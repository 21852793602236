import clsx from 'clsx';
import { HTMLAttributes, useState } from 'react';

/* 우대 이용 시설 */
export default function PreferentialFacilities({
  className,
  children,
}: HTMLAttributes<HTMLDivElement>) {
  const [side, setSide] = useState<'back' | 'front'>('front');

  const handleClick = () => {
    setSide(side === 'front' ? 'back' : 'front');
  };

  if (side == 'front') {
    return (
      <div className={clsx('info_box', className)} onClick={handleClick}>
        {children}
      </div>
    );
  }
  return (
    <div className="info_box box_type2">
      <p className="new_ico">
        <img src="img/new_ico.svg" alt="새로고침버튼" onClick={handleClick} />
      </p>
      <div className="info_txt">
        <p className="title">우대이용시설</p>
        <ul className="ctt_txt">
          <li>
            와인터널 입장료 3000원
            <p className="discount">(40%감면)</p>
          </li>
          <li>
            난계국악박물관 입장료 1500원
            <p className="discount">(25%감면)</p>
          </li>
          <li>
            힐링센터 입장료 1000원
            <p className="discount">(33%감면)</p>
          </li>
        </ul>
        <p className="s_txt">이 증은 타인에게 양도 할 수 없습니다.</p>
      </div>
      <p className="info_img">
        <img src="img/box_bg3.svg" alt="우대이용시설 배경" />
      </p>
    </div>
  );
}
