import { consentAll, toggleConsent } from '@/features/formDataSlice';
import { selectFormData } from '@/features/selectors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Typography,
} from '@mui/material';
import { ReactNode, SyntheticEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './TermsOfService.scss';

interface TermsOfServiceProps {
  name: string;
}

interface ItemProps {
  children: ReactNode;
  id: keyof Consent;
  title: string;
}

function Item({ children, id, title }: ItemProps) {
  const dispatch = useDispatch();
  const formData = useSelector(selectFormData);
  const [expanded, setExpanded] = useState(false);
  const checked = formData[id];

  const handleChange = (event: SyntheticEvent) => {
    event.isPropagationStopped() || setExpanded(!expanded);
  };

  const handleCheck = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.nativeEvent.stopPropagation();
    dispatch(toggleConsent(id));
  };

  return (
    <Accordion expanded={expanded} key={id} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Checkbox size="small" checked={checked} onClick={handleCheck} />
        <Typography
          className="accordion-text"
          sx={{ width: '100%', flexShrink: 0 }}
        >
          {title}
          <span className="text-star">*</span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="detail">{children}</AccordionDetails>
    </Accordion>
  );
}

export function TermsOfService(props: TermsOfServiceProps) {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(consentAll());
  };
  return (
    <div className="terms-container">
      <span id="terms-text">회원가입을 위해 약관에 동의해주세요.</span>
      <Button className="terms-btn" onClick={handleClick} variant="contained">
        {props.name}
      </Button>
      <div className="accordion-container">
        <TermsOfServiceItem />
        <PrivacyPolicyItem />
        <ThirdPartyProvisionItem />
      </div>
    </div>
  );
}

// eslint-disable-next-line max-lines-per-function
const PrivacyPolicyItem = () => (
  <Item id="indvdlinfoUseAgreAt" title="개인정보 수집 및 이용 동의(필수)">
    <h2>Ⅲ. 개인정보처리방침 안내문</h2>
    <p>
      제1조 총칙
      <br />
      충청북도 영동군은 영동알리미 서비스(이하 “서비스”)를 제공하기 위해 필요한
      필수항목을 이용자의 동의 하에 수집하고 있습니다. 개인정보란 생존하는
      개인에 관한 정보로서 해당 정보에 포함되어 있는 성명, 주민등록번호 등의
      사항에 의하여 당해 개인을 식별할 수 있는 정보를 말합니다.
      <br />
      충청북도 영동군은 이용자의 개인정보를 소중히 취급하며, 통신비밀보호법,
      전기통신사업법, 정보통신망 이용 촉진 및 정보보호 등에 관한 법률 및
      방송통신위원회가 제정한 『개인정보보호지침』을 준수하고 있습니다.
      <br />
      충청북도 영동군은 관련 법령에 의거하여 개인정보취급방침을 통해 이용자의
      권익 보호를 위해 최선을 다하고 있습니다.
      <br />
    </p>
    <p>
      제2조 수집하는 개인정보 항목
      <br />
      충청북도 영동군은 회원 가입 시 서비스 제공을 위해 필요한 최소한의
      개인정보만을 수집합니다. 수집하는 항목은 다음과 같습니다.
      <br />① 회원가입 시 : CI(암호화된 본인인증 결과값), 전화번호, 이름,
      생년월일, 성별
      <br />② 서비스 이용 및 사용자 보호를 위한 자동 생성 정보, 위치정보(모든
      정보는 사용자 이외에는 일체 노출되지 않습니다.)
      <br />③ 자동으로 수집되는 정보의 거부를 위해서는 해당 권한 요청(위치정보)
      시 거부를 하면 수집되지 않습니다. 단, 위치정보에 대한 권한 요청을 거부하면
      맞춤 알림서비스를 받지 못합니다.
      <br />
    </p>
    <p>
      제3조 개인정보의 수집방법 및 동의
      <br />
      충청북도 영동군은 회원의 개인정보 수집 및 이용에 대한 동의를 받고
      있습니다. 충청북도 영동군에서 제공하는 서비스는 스마트폰의 플레이스토어,
      앱스토어에서 서비스를 설치하는 해당 회원가입 시 개인정보취급방침에 대한
      동의 절차를 마련하여, 동의 버튼을 클릭하면 개인정보 수집 및 이용에 대해
      동의한 것으로 봅니다. 다만, 아래의 경우에는 개인정보 수집 및 이용에 대한
      회원의 사전 동의를 구하지 않을 수 있습니다.
      <br />① 정보통신서비스의 제공에 관한 계약을 이행하기 위하여 필요한
      개인정보로서 경제적 기술적인 사유로 통상적인 동의를 받는 것이 뚜렷하게
      곤란한 경우
      <br />② 정보통신서비스의 제공에 따른 요금정산을 위하여 필요한 경우
      <br />③ 다른 법률에 특별한 규정이 있는 경우
      <br />
    </p>
    <p>
      제4조 개인정보의 수집 및 이용목적
      <br />
      충청북도 영동군은 수집한 개인정보를 다음의 목적을 위해 활용합니다.
      <br />① 회원 관리
      <br />
      회원제 서비스 이용에 따른 본인확인, 개인식별, 불량회원의 부정 이용 방지와
      비인가 사용 방지, 가입 의사 확인, 분쟁 조정을 위한 기록보존, 불만처리 등
      민원 처리
      <br />② 서비스 제공에 필요한 제2조의 개인정보항목
      <br />
    </p>
    <p>
      제5조 개인정보의 제 3자 제공 및 공유에 대한 동의
      <br />
      충청북도 영동군은 회원이 공개를 동의한 경우나 충청북도 영동군의 이용약관,
      회사가 별도로 정한 정책 및 운영 규정에 어긋나는 행위를 하여 법적인 조치가
      요구되거나 관련 정부 기관의 요구가 있는 경우를 제외하고는 어떠한 경우에도
      본 개인 정보 보호정책에서 고지한 범위를 넘어 회원의 개인정보를 이용하거나
      타인 또는 타기업, 기관에 제공하지 않습니다.
      <br />본 개인정보 보호정책에 의한 회원의 개인정보 제공 및 공유에 추가하여
      개인정보를 제공하거나 공유하는 경우에는 사전에 회원에게 제공받거나
      공유하는 자가 누구이며 주된 사업이 무엇인지, 제공 또는 공유되는 개인 정보
      항목이 무엇인지, 개인정보를 제공하거나 공유하는 목적이 무엇인지 등에 대해
      개인정보 보호정책, 이메일 또는 서면을 통해 고지한 후 이에 동의를 구합니다.
      <br />
      다만, 다음의 경우에는 관련 법령의 규정에 의하여 회원의 동의 없이
      개인정보를 제공하는 것이 가능합니다.
      <br />① 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정
      개인을 알아 볼 수 없는 형태로 가공하여 제공하는 경우
      <br />② 금융실명거래 및 비밀보장에 관한 법률, 신용정보의 이용 및 보호에
      관한 법률, 전기통신기본법, 전기통신사업법, 지방세법, 소비자기본법,
      한국은행법, 형사소송법 등 법률에 특별한 규정이 있는 경우 충청북도 영동군은
      서비스 제공을 위하여 제공된 개인 정보는 별도 동의를 득한 경우를 제외하고는
      서비스 해지(탈퇴 신청 포함) 후 30일까지 보관합니다.
      <br />
    </p>
    <p>
      제6조 이용자 및 법정대리인의 권리와 행사 방법
      <br />
      이용자는 언제든지 ‘영동알리미 &gt; 내정보’에서 자신의 개인정보를
      조회하거나 수정할 수 있습니다.
      <br />
      이용자는 언제든지 ‘서비스 탈퇴’ 등을 통해 개인정보의 수집 및 이용 동의를
      철회할 수 있습니다.
      <br />만 14세 미만의 아동의 경우, 법정대리인이 아동의 개인정보를
      조회하거나 수정할 권리, 수집 및 이용 철회할 권리를 가집니다.
      <br />
    </p>
    <p>
      제7조 개인정보 수집ㆍ이용ㆍ제공에 대한 동의 철회
      <br />
      회원은 개인정보 수집, 이용 및 제공에 대해 가입 시 동의하신 내용을 언제든지
      철회하실 수 있습니다. ‘서비스 탈퇴’ 버튼을 누르면 모든 정보는 회사가
      규정한 기간 내에 삭제됩니다.
      <br />
    </p>
    <p>
      제8조 개인정보의 보유기간 및 이용기간
      <br />
      충청북도 영동군은 회원이 회원자격을 유지하고 있는 동안에 회원이 충청북도
      영동군에 제공한 개인정보를 계속 보유합니다. 개인정보제공에 대해
      동의철회(서비스 탈퇴) 할 경우 수집된 개인정보를 파기하며, 예외 규정을
      제외하고 어떠한 목적으로도 사용할 수 없도록 합니다.
      <br />
    </p>
    <p>
      제9조 개인정보 파기절차 및 방법
      <br />
      충청북도 영동군은 개인정보의 수집 목적 또는 제공받은 목적이 달성된 때에는
      회원의 개인정보를 지체없이 파기합니다.
      <br />① 파기 대상
      <br />
      회원이 서비스 해지(탈퇴 신청 포함)를 요청한 경우 지체없이 파기(단, 수집
      목적 또는 제공받은 목적이 달성된 경우에도 형법 등 법령의 규정에 의하여
      보존할 필요성이 있는 경우에는 예외적으로 회원의 개인정보를 보유할 수
      있습니다.)
      <br />
      정당한 절차에 따라 보유 기간을 미리 회원에게 고지하거나 명시한 경우
      <br />
      개별적으로 회원의 동의를 받은 경우
      <br />
      회원의 추가적인 피해 방지를 위해 30일간 보관하는 경우
      <br />② 파기 방법
      <br />
      종이에 출력된 개인정보 : 분쇄기로 분쇄
      <br />
      전자적 파일 형태로 저장된 개인정보 : 기록을 재생할 수 없는 기술적 방법으로
      삭제
      <br />
    </p>
    <p>
      제10조 개인정보보호를 위한 기술적ㆍ관리적 대책
      <br />
      충청북도 영동군은 개인정보를 취급함에 있어 분실, 도난, 누출, 변조 또는
      훼손의 안전성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다.
      <br />① 비밀번호의 암호화
      <br />
      회원의 비밀번호(Password)는 암호화되어 저장 및 관리되고 있습니다.
      <br />
      비밀번호는 오직 회원 본인만 알 수 있으며 개인정보를 확인 및 변경할
      경우에도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.
      <br />② 해킹 및 컴퓨터 바이러스 등에 대비
      <br />
      충청북도 영동군은 해킹이나 컴퓨터 바이러스에 의하여 회원의 개인정보가
      유출되거나 훼손되는 것을 막기 위하여 필요한 보안조치를 이용하고 있으며,
      더욱 향상된 보안조치를 확보할 수 있도록 가능한 모든 기술적 방법을 구비하기
      위하여 노력하고 있습니다.
      <br />③ 개인정보 취급자의 제한 및 교육
      <br />
      충청북도 영동군은 개인정보를 취급하는 직원을 최소한으로 제한하고 있으며,
      관련 직원들에 대한 교육을 수시로 실시하여 본 방침의 이행 및 준수 여부를
      확인하고 있습니다.
      <br />
    </p>
    <p>
      제11조 개인정보 관리 책임자 및 담당자
      <br />
      회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와
      같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다. 서비스를
      이용하시면서 발생하는 모든 개인정보보호 관련 민원을 개인정보 관리책임자
      혹은 담당자에게 신고하시면 신속하게 답변해 드리도록 하겠습니다.
      <br />
    </p>
    <ol>
      <li>개인정보관리책임</li>
    </ol>
    <p>
      담당부서 : 충청북도 영동군 행정과
      <br />대 표 자 : 충청북도 영동군수
      <br />주 소 : 충청북도 영동군 영동읍 동정로 1<br />
      대표전화 : 043-740-3114
      <br />
    </p>
    <ol>
      <li>개인정보관리담당자</li>
    </ol>
    <p>
      소 속 : 충청북도 영동군 행정과
      <br />
      책임자 : 충청북도 영동군 행정과 과장
      <br />
      연락처 :<br />
      이메일 :<br />
    </p>
    <p>
      제12조 개인정보 침해 관련 상담 및 신고
      <br />① 충청북도 영동군 자체적인 개인정보 불만처리, 피해구제 결과에
      만족하지 못하거나, 보다 자세한 도움이 필요할 경우, 아래의 기관에 대해 개인
      정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.
      <br />
    </p>
    <p>
      ▶ 개인정보 침해신고센터(한국인터넷진흥원 운영) :<br />
      (국번없이) 118 (<a>privacy.kisa.or.kr</a>)
    </p>
    <p>
      ▶ 개인정보 분쟁조정위원회 :<br />
      (국번없이) 1833-6972 (
      <a href="http://www.kopico.go.kr/">www.kopico.go.kr</a>)
    </p>
    <p>
      ▶ 대검찰청 사이버범죄수사단 :<br />
      02-3480-3573 (<a href="http://www.spo.go.kr/">www.spo.go.kr</a>)
    </p>
    <p>
      ▶ 경찰청 사이버안전국 :<br />
      (국번없이) 182 (
      <a href="http://cyberbureau.police.go.kr/">cyberbureau.police.go.kr</a>)
    </p>
    <p>
      ② 개인정보의 열람, 정정·삭제, 처리정지 등에 대한 정보주체자의 요구에
      대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익을
      침해 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수
      있습니다.
    </p>
    <p>
      ▶ 중앙행정심판위원회:
      <br />
      (국번없이) 110 (<a href="http://www.simpan.go.kr/">www.simpan.go.kr</a>)
    </p>
    <p>
      부칙
      <br />
      충청북도 영동군은 개인정보취급방침의 관련 서비스 제공을 위하여 정부의 정책
      또는 위치정보측위 기술의 변경에 따라 내용의 추가 또는 삭제 및 수정이 있을
      시에는 개정 전에 홈페이지의 공지란을 통해 고지 또는 회원이 사용하는
      어플리케이션의 알림 창을 통해 고지할 수 있습니다.
      <br />
    </p>
    <p>(시행일) 본 개인정보처리방침은 2024년 4월 1일부터 시행합니다.</p>
  </Item>
);

// eslint-disable-next-line max-lines-per-function
const TermsOfServiceItem = () => (
  <Item id="useStplatAgreAt" title="서비스 이용약관(필수)">
    <h2>I. 서비스 이용약관</h2>
    <h3>제1장 총칙</h3>
    <p>
      제1조(목적)
      <br />이 약관은 충청북도 영동군 영동알리미서비스(이하 “서비스”)를 이용함에
      있어 이용자와 충청북도 영동군간의 권리ㆍ의무 및 책임사항과 기타 필요한
      사항을 규정함을 목적으로 합니다.
      <br />
    </p>
    <p>
      제2조(용어의 정의)
      <br />① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
    </p>
    <ol>
      <li>
        이용자 : 영동알리미에 접속하여 충청북도 영동군이 제공하는 서비스를
        이용하는 회원
      </li>
      <li>
        회원 : 서비스에 접속하여 약관에 동의하고, 휴대폰본인인증(CI : 사용자
        고유코드) 확인 절차를 거친 자
      </li>
      <li>
        CI(Customer Identity) : 회원의 서비스 이용을 위하여 인증기관(PASS,
        디지털원패스 등)에서 제공받는 사용자 고유코드
      </li>
      <li>
        이용해지 : 충청북도 영동군 또는 회원이 서비스 이용계약을 종료시키는 의사
        표시
      </li>
      <li>
        이용계약 : 서비스 이용과 관련하여 충청북도 영동군과 이용자 간에 체결하는
        계약전체를 말하며, 본 약관은 이용계약 일부를 구성함
        <br />
      </li>
    </ol>
    <p>
      ② 이 약관에서 사용하는 용어의 정의는 제1항에서 정의하는 것을 제외하고는
      관계 법령 및 서비스별 안내에서 정하는 바에 의합니다.
      <br />
    </p>
    <p>
      제3조(약관의 효력 및 변경)
      <br />① 이 약관은 영동알리미 화면에서 게시하거나 기타의 방법으로 공지하고
      이에 동의한 이용자가 영동알리미에 가입함으로써 효력이 발생합니다.
      <br />② 충청북도 영동군은 합리적인 사유가 있는 경우 본 약관을 관계 법령에
      어긋나지 않는 범위 내에서 변경할 수 있으며, 약관이 변경될 경우 충청북도
      영동군은 적용 일자 및 변경 사유를 명시하여 약관 적용일 7일 이전부터
      공지합니다. 다만, 이용자에게 불리하게 약관을 변경될 경우에는 최소한 적용일
      30일 이전부터 이용자에게 개별 통지합니다. 단, 이용자의 연락처 미기재, 변경
      후 미수정 등으로 인하여 개별 통지가 어려운 경우에는 개별 통지한 것으로
      간주 합니다.
      <br />③ 충청북도 영동군이 제2항에 따라 변경 약관을 공지 또는 통지하고
      이용자가 약관 변경 적용일까지 거부 의사를 표시하지 않을 경우 변경 약관에
      동의한 것으로 간주합니다. 약관 변경에 동의하지 않은 이용자를 대상으로
      서비스 이용 중지 및 탈퇴(해지)를 요청할 수 있습니다.
      <br />④ 이용자가 이 약관의 내용에 동의하는 경우 충청북도 영동군의 서비스
      제공행위 및 이용자의 서비스 이용행위에는 이 약관이 우선적으로 적용될
      것입니다.
      <br />⑤ 이 약관에 명시되지 않은 사항에 대해서는 전기통신기본법,
      전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률,
      방송통신심의위원회 심의 규정, 정보통신 윤리강령, 프로그램보호법 등 기타
      대한민국의 관련법령과 상관습에 의합니다.
      <br />
    </p>
    <h3>제2장 서비스 이용계약</h3>
    <p>
      제4조(이용계약의 성립)
      <br />
      이용계약은 이용자의 약관 내용에 대한 동의와 이용자의 회원가입으로
      성립합니다.
      <br />
    </p>
    <p>
      제5조(회원가입)
      <br />
      PASS, 디지털원패스 등 인증을 통해 영동알리미의 회원가입 화면에서 이용자가
      충청북도 영동군에서 요구하는 가입 양식에 개인의 신상정보를 기록하는
      방식으로 가입합니다.
      <br />
    </p>
    <p>
      제6조(회원가입의 수락과 거절)
      <br />① 충청북도 영동군은 제5조에서 정한 사항을 정확히 기재하여 회원가입
      요청을 하였을 경우 특별한 사정이 없는 한 서비스 회원가입을 수락합니다.
      <br />② 충청북도 영동군은 다음 각호에 해당하는 경우에는 그 신청에 대한
      제한 사유가 해소될 때까지 일부 서비스 이용을 제한할 수 있습니다.
    </p>
    <ol>
      <li>
        내용이 허위(차명 등)인 것으로 판명되거나, 그러하다고 의심할만한 합리적인
        사유가 발생할 경우
      </li>
      <li>기타 충청북도 영동군이 필요하다고 인정되는 경우</li>
    </ol>
    <p>
      ③ 충청북도 영동군은 다음 각호에 해당하는 경우에는 이용 신청을 제한할 수
      있습니다.
    </p>
    <ol>
      <li>다른 사람의 명의를 사용하여 신청하였을 때</li>
      <li>신청서의 내용을 허위로 기재하였을 때</li>
      <li>사회의 안녕과 질서 또는 미풍양속을 저해할 목적으로 신청하였을 때</li>
      <li>기타 충청북도 영동군이 정한 이용 신청 요건이 미비 되었을 때</li>
    </ol>

    <p>
      제7조(계약사항의 변경)
      <br />
      회원은 회원정보 관리를 통해 언제든지 자신의 정보를 열람하고 수정할 수
      있습니다. 회원은 이용 신청 시 기재한 사항이 변경되었을 때는 수정하여야
      하며, 수정하지 아니하여 발생하는 문제의 책임은 회원에게 있습니다.
      <br />
    </p>
    <h3>제3장 서비스 제공 및 이용</h3>
    <p>
      제8조(서비스 이용)
      <br />① 충청북도 영동군은 회원의 이용 신청을 수락한 때부터 서비스를
      개시합니다. 단, 일부 서비스의 경우에는 지정된 일자부터 서비스를
      개시합니다.
      <br />② 충청북도 영동군의 업무상 또는 기술상의 장애로 인하여 서비스를
      개시하지 못할 때는 영동알리미에 공지하거나 회원에게 이를 통지합니다.
      <br />③ 서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만,
      충청북도 영동군의 업무상 또는 기술상의 이유로 서비스가 일시 중지될 수
      있고, 또한 정기점검 등 운영상의 목적으로 충청북도 영동군이 정한 기간에는
      서비스가 일시 중지될 수 있습니다.
      <br />
      이러한 경우 충청북도 영동군은 사전 또는 사후에 이를 공지합니다.
      <br />④ 회원에 가입한 후라도 일부 서비스 이용 시 서비스 제공자의 요구에
      따라 특정 회원에게만 서비스를 제공할 수 도 있습니다.
      <br />
      제9조(서비스의 변경, 중지 및 정보의 저장과 사용)
      <br />① 본 서비스에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의
      내용이 국가의 비상사태, 정전, 서버 장애 및 기타 불가항력에 의하여 보관되지
      못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실에
      대해 충청북도 영동군이 어떠한 책임도 지지 않습니다.
      <br />② 충청북도 영동군이 정상적인 서비스 제공의 어려움으로 인하여
      일시적으로 서비스를 중지하여야 할 경우에는 사전 고지 후 서비스를 중지할 수
      있으며 이 기간 동안 회원이 고지내용을 인지하지 못할 경우 충청북도 영동군은
      책임을 지지 않습니다.
      <br />③ 충청북도 영동군의 사정으로 서비스를 영구적으로 중단하여야 할 경우
      제2항을 준용합니다. 다만, 이 경우 사전 고지기간은 1개월로 합니다.
      <br />④ 충청북도 영동군은 사전 고지 후 서비스를 일시적으로 수정, 변경 및
      중단할 수 있으며 이에 대하여 회원 또는 제3자에게 어떠한 책임도 부담하지
      아니합니다.
      <br />⑤ 충청북도 영동군은 회원이 이 약관의 내용에 어긋나는 행동을 한 경우
      임의로 서비스 사용을 중지할 수 있습니다. 이 경우 충청북도 영동군은 회원의
      접속을 금지할 수 있으며 회원이 게시한 내용의 전부 또는 일부를 임의로
      삭제할 수 있습니다.
      <br />⑥ 장기간 서비스를 이용하지 않는 휴면 회원에 대해서는 SMS(휴대폰
      문자메세지), 푸시알림 등을 통해 1주일간 공지 후 서비스 사용을 중지할 수
      있습니다.
      <br />⑦ 장기간 휴면 상태인 회원의 개인정보를 파기하는 경우 민원접수 처리
      및 사후관리 서비스 제공을 위해 수집한 정보는 이용자 불만 및 분쟁 해결
      목적으로 별도로 분리하여 CI와 이름을 안전하게 보관합니다.
      <br />
    </p>
    <p>
      제10조(서비스의 내용)
      <br />① 영동알리미는 건강, 경제, 귀농귀촌, 행사, 복지, 임신출산, 교육 등
      맞춤서비스와 위치기반 개인형 맞춤 서비스를 제공합니다.
      <br />② 본 서비스는 이용자의 충청북도 영동군의 생활 편의 향상 및 다양한
      맞춤 서비스를 편리하게 이용할 수 있도록 지원합니다.
      <br />
    </p>
    <p>
      제11조(정보의 제공 및 광고의 게재)
      <br />① 충청북도 영동군은 회원이 서비스 이용 중 필요가 있다고 인정되는
      다양한 정보 및 광고에 대해서는 전자우편, SMS(휴대폰 문자메시지), 푸시 알림
      등의 방법으로 회원에게 제공할 수 있습니다.
      <br />② 충청북도 영동군은 서비스상에 게재되어 있거나 서비스를 통한
      광고주와의 판촉 활동에 회원이 참여하거나 교신 또는 거래의 결과로서
      발생하는 모든 손실 또는 손해에 대한 책임을 지지 않습니다.
      <br />
    </p>
    <p>
      제12조(게시물 또는 내용물의 삭제)
      <br />① 충청북도 영동군은 회원이 게시하거나 등록하는 서비스 내의 모든
      내용물이 다음 각호의 경우에 해당된다고 판단되는 경우 사전통지 없이 삭제할
      수 있으며, 이에 대해 충청북도 영동군은 어떠한 책임도 지지 않습니다.
      <br />
    </p>
    <ol>
      <li>
        충청북도 영동군, 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를
        손상시키는 내용인 경우
      </li>

      <li>공공질서 및 미풍양속에 위반되는 내용인 경우</li>

      <li>범죄적 행위에 결부된다고 인정되는 내용인 경우</li>

      <li>제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</li>

      <li>서비스 성격에 부합하지 않는 정보의 경우</li>
    </ol>

    <p>
      ① 기타 관계 법령 및 충청북도 영동군에서 정한 규정 등에 어긋나는 경우
      <br />② 충청북도 영동군은 서비스에 게시된 내용을 편집, 이동, 삭제할 수
      있는 권리를 보유하며, 게시된 내용이 이 약관에 어긋나거나 상용 또는
      비합법적, 불건전한 내용일 경우 및 해지 회원이 게시한 게시물은 사전 통보
      없이 삭제할 수 있습니다.
      <br />③ 충청북도 영동군은 게시된 내용이 일정 기간 이상 경과되어
      게시물로서의 효력이 상실된 경우 해당 게시물을 삭제할 수 있습니다.
      <br />
    </p>
    <p>
      제13조(지적재산권)
      <br />① 회원의 게시물이 타인의 저작권, 프로그램저작권 등을 침해함으로써
      발생하는 민ㆍ형사상의 책임은 전적으로 회원이 부담하여야 합니다.
      <br />② 충청북도 영동군이 제공하는 서비스에 필요한 이미지, 마크, 로고,
      디자인, 서비스 명칭, 정보 및 상표 등과 관련된 지적재산권 및 기타 권리는
      충청북도 영동군에 있습니다.
      <br />③ 회원은 충청북도 영동군이 명시적으로 승인한 경우를 제외하고는 제2항
      소정의 각 재산에 대한 전부 또는 일부의 수정, 대여, 대출, 판매, 배포, 제작,
      양도, 상업적 이용행위를 할 수 없으며 제3자로 하여금 이와 같은 행위를
      하도록 허락할 수 없습니다.
      <br />
    </p>
    <h3>제4장 계약 당사자의 의무</h3>
    <p>
      제14조(이용자의 의무)
      <br />① 회원은 서비스 이용을 위해 가입하는 경우 현재의 사실과 일치하는
      완전한 정보(이하 “가입정보”)를 제공하셔야 합니다. 또한 가입정보에 변경이
      발생하면 즉시 갱신하셔야 합니다.
      <br />② 회원이 서비스 사용을 위한 가입절차를 완료하면 PASS,
      디지털원패스로부터 CI코드값을 받게 됩니다. 회원의 개인정보 관리를 위하여
      다음을 준수하여야 합니다.
      <br />
    </p>
    <ol>
      <li>
        회원의 승인없이 개인정보가 사용되는 문제가 발생하면 즉시 충청북도
        영동군에 신고하셔야 합니다.
      </li>
    </ol>
    <p>
      ③ 회원은 서비스를 이용하면서 다음과 같은 행위를 하지 않기로 동의합니다.
      <br />
    </p>
    <ol>
      <li>
        타인(소수를 포함)에게 위해를 가하는 행위
        <br />
        가. 타인의 개인정보 및 타인으로 가장하는 행위
        <br />
        나. 타인과의 관계를 허위로 명시하는 행위
        <br />
        다. 타인을 비방할 목적으로 사실 또는 허위의 사실을 적시하여 명예를
        훼손하는 행위
        <br />
        라. 자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 입힐
        목적으로 허위의 정보를 유통하는 행위
        <br />
        마. 충청북도 영동군의 사전 승낙 없이 서비스를 이용한 영리 행위
        <br />
        바. 타인의 정보통신 서비스의 이용 명의를 도용하여 사용하는 행위
        <br />
      </li>

      <li>
        컴퓨터 소프트웨어, 하드웨어, 전기통신 장비를 파괴, 방해 또는 기능을
        제한하기 위한 소프트웨어 바이러스를 게시, 게재 또는 전자우편으로 보내는
        행위
      </li>

      <li>
        다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시, 게재,
        전자우편으로 보내는 행위 등 다른 사용자의 개인정보를 수집 또는 저장하는
        행위
      </li>

      <li>재물을 걸고 도박하거나 사행행위를 하는 행위</li>

      <li>
        윤락행위를 알선하거나 음행을 매개하는 내용의 정보를 유통시키는 행위
      </li>

      <li>기타 불법적이거나 부당한 행위</li>
    </ol>
    <p>④ 회원은 이 약관 및 관계 법령에서 규정한 사항을 준수하여야 합니다.</p>
    <p>
      제15조(충청북도 영동군의 의무)
      <br />① 충청북도 영동군은 특별한 사정이 없는 한 회원이 신청한 서비스 제공
      개시일에 서비스를 이용할 수 있도록 합니다.
      <br />② 충청북도 영동군은 이 약관에서 정한 바에 따라 계속적, 안정적으로
      서비스를 제공할 의무가 있습니다.
      <br />③ 충청북도 영동군은 무료로 제공되는 서비스와 관련하여 회원에게
      어떠한 손해가 발생하더라도 동 손해가 충청북도 영동군의 중대한 과실에 의한
      경우를 제외하고 이에 대하여 책임을 부담하지 아니합니다.
      <br />④ 충청북도 영동군은 회원으로부터 제기되는 의견이나 불만이 정당하다고
      인정되면 신속하게 처리하여야 합니다. 다만, 신속한 처리가 곤란한 경우에는
      회원에게 그 사유와 처리 일정을 통보하여야 합니다.
      <br />
    </p>
    <p>
      제16조(개인정보보호정책)
      <br />① 충청북도 영동군은 이용 신청 시 회원이 제공하는 개인정보는
      영동알리미 서비스 제공을 목적으로 수집ㆍ이용합니다.
      <br />② 충청북도 영동군은 이용자의 개인정보 보호를 매우 중요시하며
      개인정보보호법, 정보통신망법 등 관계 법령이 정하는 바에 따라 이용자의
      개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 이용에 대해서는
      관계 법령 및 충청북도 영동군의 개인정보처리방침이 적용됩니다.
      <br />③ 충청북도 영동군은 서비스 제공과 관련하여 취득한 회원의 정보를
      본인의 승낙없이 제3자에게 누설 또는 배포할 수 없으며 상업적 목적으로
      사용할 수 없습니다. 다만, 다음의 각호의 경우에는 그러하지 아니합니다.
      <br />
    </p>
    <ol>
      <li>
        관계 법령에 따라 수사상의 목적으로 관계 기관으로부터 요구가 있는 경우
      </li>

      <li>방송통신심의위원회의 요청이 있는 경우</li>

      <li>기타 관계 법령에서 정한 절차에 따른 요청이 있는 경우</li>
    </ol>
    <h3>제5장 계약 해지</h3>
    <p>제17조(계약 해지 및 이용 제한)</p>
    <p>
      ① 회원이 이용계약을 해지하고자 하실 때는 회원 본인이 직접 서비스 이용 해지
      신청을 하여야 합니다.
      <br />② 충청북도 영동군은 보안 및 개인정보 정책, 서비스의 원활한 제공 등과
      같은 이유로 회원의 CI코드값 변경을 요구하거나 변경할 수 있습니다.
      <br />③ 충청북도 영동군은 회원이 다음 각호에 해당하는 행위를 하였을 경우
      사전통지 없이 이용계약을 해지할 수 있습니다.
    </p>
    <ol>
      <li>회원이 제공한 데이터가 허위임이 판명된 경우</li>

      <li>범죄적 행위에 관련되는 경우</li>

      <li>
        국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행할
        경우
      </li>

      <li>타인의 서비스 아이디 및 비밀번호를 도용한 경우</li>

      <li>타인의 명예를 손상하거나 불이익을 주는 경우</li>

      <li>서비스에 위해를 가하는 등 서비스의 건전한 이용을 저해하는 경우</li>
      <li>
        기타 관련 법령이나 충청북도 영동군이 정한 이용조건에 어긋나는 경우
      </li>
    </ol>
    <h3>제6장 기타</h3>
    <p>
      제18조(양도 금지)
      <br />
      회원은 서비스의 이용권한, 기타 이용 계약상의 지위를 타인에게 양도, 증여할
      수 없으며, 이를 담보로 제공할 수 없습니다.
      <br />
    </p>
    <h3>제7장 손해배상 등</h3>
    <p>
      제19조(손해배상) 충청북도 영동군은 무료로 제공되는 서비스와 관련하여
      회원에게 어떠한 손해가 발생하더라도 동 손해가 충청북도 영동군의 중대한
      과실에 의한 경우를 제외하고는 이에 대하여 책임을 부담하지 아니합니다.
    </p>
    <p>
      제20조(면책조항)
      <br />① 충청북도 영동군은 비상사태, 천재지변, 정전, 서버 장애 또는 이에
      준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에
      관한 책임이 면제됩니다.
      <br />② 충청북도 영동군은 회원의 귀책 사유로 인한 서비스 이용의 장애에
      대하여 책임을 지지 않습니다.
      <br />③ 충청북도 영동군은 회원이 서비스를 이용하여 기대하는 이익이나
      서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.
      <br />④ 충청북도 영동군은 회원이 서비스에 게재한 정보, 자료, 사실의
      신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.
      <br />
    </p>
    <p>
      제21조(관할법원)
      <br />① 서비스 이용과 관련하여 충청북도 영동군과 회원사이에 분쟁이 발생한
      경우, 충청북도 영동군과 회원은 발생한 분쟁을 원만하게 해결하기 위하여
      필요한 모든 노력을 하여야 합니다.
      <br />② 제1항의 규정에도 불구하고 서비스 이용으로 발생한 분쟁에 대하여
      소송이 제기될 경우 충청북도 영동군 소재지를 관할하는 법원을 관할법원으로
      합니다.
      <br />
    </p>
    <h2>Ⅱ. 위치기반 서비스 이용약관</h2>
    <h3>제1장 총칙</h3>
    <p>
      제1조(목적)
      <br />본 약관은 회원(서비스 이용약관에 동의한 자를 말합니다. 이하
      “회원”이라고 합니다.)이 충청북도 영동군이 제공하는 위치기반서비스(이하
      “서비스”라고 합니다)를 이용함에 있어 충청북도 영동군과 회원의 권리·의무 및
      책임사항을 규정함을 목적으로 합니다.
      <br />
    </p>
    <p>
      제2조(이용약관의 효력 및 변경)
      <br />① 본 약관은 서비스를 신청한 회원 또는 개인위치정보주체가 본 약관에
      동의하고 충청북도 영동군이 정한 소정의 절차에 따라 서비스의 이용자로
      등록함으로써 효력이 발생합니다.
      <br />② 회원이 온라인에서 본 약관의 &quot;동의하기&quot; 버튼을 클릭하였을
      경우 본 약관의 내용을 모두 읽고 이를 충분히 이해하였으며, 그 적용에 동의한
      것으로 봅니다.
      <br />③ 충청북도 영동군은 위치정보의 보호 및 이용 등에 관한 법률,
      콘텐츠산업 진흥법, 전자상거래 등에서의 소비자보호에 관한 법률,
      소비자기본법 약관의 규제에 관한 법률 등 관련법령을 위배하지 않는 범위에서
      본 약관을 개정할 수 있습니다.
      <br />④ 충청북도 영동군이 약관을 개정할 경우에는 기존약관과 개정약관 및
      개정약관의 적용일자와 개정사유를 명시하여 기존 약관과 함께 그 적용일자 7일
      전부터 적용일 이후 상당한 기간 동안 공지만을 하고, 개정 내용이 회원에게
      불리한 경우에는 그 적용일자 30일 전부터 적용일 이후 상당한 기간 동안 각각
      이를 서비스 누리집에 게시하거나 회원에게 전자적 형태(전자우편, SMS 등)로
      약관 개정 사실을 발송하여 고지합니다.
      <br />⑤ 충청북도 영동군이 전항에 따라 회원에게 통지하면서 공지 또는
      공지∙고지일로부터 개정약관 시행일 7일 후까지 거부 의사를 표시하지 아니하면
      이용약관에 승인한 것으로 봅니다. 회원이 개정약관에 동의하지 않을 경우
      회원은 이용계약을 해지할 수 있습니다.
      <br />
    </p>
    <p>
      제3조(관계법령의 적용)
      <br />본 약관은 신의성실의 원칙에 따라 공정하게 적용하며, 본 약관에
      명시되지 아니한 사항에 대하여는 관계법령 또는 상관례에 따릅니다.
      <br />
    </p>
    <p>
      제4조(서비스의 내용)
      <br />
      충청북도 영동군은 직접 위치정보를 수집하거나 위치정보사업자로부터
      위치정보를 전달받아 아래와 같은 위치기반서비스를 제공합니다.
      <br />
    </p>
    <ol>
      <li>
        개인별 맞춤 알림 : 영동군 WIFI 연결 시 AP를 이용하여 위치 정보를
        확인하고 개인에 맞는 알림 서비스를 제공합니다.
      </li>
      <li>장소 출도착 알림</li>
    </ol>
    <p>
      제5조(서비스 이용요금)
      <br />① 충청북도 영동군이 제공하는 서비스는 기본적으로 무료입니다.
      <br />② 무선 서비스 이용 시 발생하는 데이터 통신료는 별도이며 가입한 각
      이동통신사의 정책에 따릅니다.
      <br />
      제6조(서비스내용변경 통지 등)
      <br />① 충청북도 영동군이 서비스 내용을 변경하거나 종료하는 경우 충청북도
      영동군은 회원의 등록된 전자우편 주소 또는 통신 단말장치 등을 통하여 서비스
      내용의 변경 사항 또는 종료를 통지할 수 있습니다.
      <br />② 제①항의 경우 불특정 다수인을 상대로 통지를 함에 있어서는 웹사이트
      등 기타 충청북도 영동군의 공지사항을 통하여 회원들에게 통지할 수 있습니다.
      <br />
    </p>
    <p>
      제7조(서비스이용의 제한 및 중지)
      <br />① 충청북도 영동군은 아래 각 호의 1에 해당하는 사유가 발생한 경우에는
      회원의 서비스 이용을 제한하거나 중지시킬 수 있습니다.
      <br />
    </p>
    <ol>
      <li>
        회원이 영동알리미 서비스의 운영을 고의 또는 중과실로 방해하는 경우
      </li>

      <li>서비스용 설비 점검, 보수 또는 공사로 인하여 부득이한 경우</li>

      <li>
        전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우
      </li>

      <li>
        국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스
        이용에 지장이 있는 때
      </li>

      <li>
        기타 중대한 사유로 인하여 충청북도 영동군이 서비스 제공을 지속하는 것이
        부적당하다고 인정하는 경우
        <br />② 충청북도 영동군은 전항의 규정에 의하여 서비스의 이용을
        제한하거나 중지한 때에는 그 사유 및 제한기간 등을 회원에게 알려야
        합니다.
        <br />
      </li>
    </ol>
    <p>
      제8조(개인위치정보의 이용 또는 제공)
      <br />① 충청북도 영동군은 개인위치정보를 이용하여 서비스를 제공하고자 하는
      경우에는 미리 이용약관에 명시한 후 개인위치정보주체의 동의를 얻어야
      합니다.
      <br />② 회원 및 법정대리인의 권리와 그 행사방법은 제소 당시의 이용자의
      주소에 의하며, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로
      합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국
      거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
      <br />③ 충청북도 영동군은 서비스 이용자와의 민원처리를 위해 위치정보
      이용·제공·사실 확인자료를 자동 기록·보존하며, 해당 자료는 6개월 이상
      보관합니다.
      <br />④ 충청북도 영동군은 개인위치정보주체의 동의 없이 개인위치정보를
      제3자에게 제공하지 아니하며, 제3자에게 개인위치정보를 제공하는 경우에는
      제공 받는자 및 제공목적을 사전에 개인위치정보주체에게 고지하고 동의를
      받습니다.
      <br />
    </p>
    <p>
      제9조(개인위치정보주체의 권리)
      <br />① 회원은 충청북도 영동군에 대하여 언제든지 개인위치정보를 이용한
      위치기반서비스 제공 및 개인위치정보의 제3자 제공에 대한 동의의 전부 또는
      일부를 철회할 수 있습니다.
      <br />② 회원은 충청북도 영동군에 대하여 언제든지 개인위치정보의 수집, 이용
      또는 제공의 일시적인 중지를 요구할 수 있으며, 충청북도 영동군은 이를
      거절할 수 없고 이를 위한 기술적 수단을 제공합니다.
      <br />③ 회원은 충청북도 영동군에 대하여 아래 각 호의 자료에 대한 열람 또는
      고지를 요구할 수 있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할
      수 있습니다. 이 경우 충청북도 영동군은 정당한 사유 없이 회원의 요구를
      거절할 수 없습니다.
      <br />
    </p>
    <ol>
      <li>본인에 대한 위치정보 수집, 이용, 제공사실 확인자료</li>
      <li>
        본인의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른
        법률 규정에 의하여 제3자에게 제공된 이유 및 내용
        <br />④ 회원은 제1항 내지 제3항의 권리행사를 위해 충청북도 영동군의
        소정의 절차를 통해 요구할 수 있습니다.
        <br />
      </li>
    </ol>
    <p>
      제10조 (법정대리인의 권리)
      <br />① 충청북도 영동군은 14세 미만의 회원에 대해서는 개인위치정보를
      이용한 위치기반서비스 제공 및 개인위치정보의 제3자 제공에 대한 동의를 당해
      회원과 당해 회원의 법정대리인으로부터 동의를 받아야 합니다. 이 경우
      법정대리인은 제9조에 의한 회원의 권리를 모두 가집니다.
      <br />② 충청북도 영동군은 14세 미만의 아동의 개인위치정보 또는 위치정보
      이용․제공사실 확인자료를 이용약관에 명시 또는 고지한 범위를 넘어
      이용하거나 제3자에게 제공하고자 하는 경우에는 14세미만의 아동과 그
      법정대리인의 동의를 받아야 합니다. 단, 아래의 경우는 제외합니다.
      <br />
    </p>
    <ol>
      <li>
        통계작성, 학술연구 또는 시장조사를 위하여 특정 개인을 알아볼 수 없는
        형태로 가공하여 제공하는 경우
      </li>
    </ol>
    <p>
      제11조(위치정보관리책임자의 지정)
      <br />① 충청북도 영동군은 위치정보를 적절히 관리·보호하고
      개인위치정보주체의 불만을 원활히 처리할 수 있도록 실질적인 책임을 질 수
      있는 지위에 있는 자를 위치정보관리책임자로 지정해 운영합니다.
      <br />② 위치정보관리책임자는 위치기반서비스를 제공하는 부서의 부서장으로서
      구체적인 사항은 본 약관의 부칙에 따릅니다.
      <br />
    </p>
    <p>
      제12조(손해배상)
      <br />① 충청북도 영동군이 위치정보의 보호 및 이용 등에 관한 법률 제15조
      내지 제26조의 규정을 위반한 행위로 회원에게 손해가 발생한 경우 회원은
      충청북도 영동군에 대하여 손해배상 청구를 할 수 있습니다. 이 경우 충청북도
      영동군은 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다.
      <br />② 회원이 본 약관의 규정을 위반하여 충청북도 영동군에 손해가 발생한
      경우 충청북도 영동군은 회원에 대하여 손해배상을 청구할 수 있습니다. 이
      경우 회원은 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수
      없습니다.
      <br />
    </p>
    <p>
      제13조(면책)
      <br />① 충청북도 영동군은 다음 각 호의 경우로 서비스를 제공할 수 없는 경우
      이로 인하여 회원에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.
      <br />
    </p>
    <ol>
      <li>천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우</li>
      <li>
        서비스 제공을 위하여 충청북도 영동군과 서비스 제휴계약을 체결한 제3자의
        고의적인 서비스 방해가 있는 경우
      </li>
      <li>회원의 귀책사유로 서비스 이용에 장애가 있는 경우</li>
      <li>
        제1호 내지 제3호를 제외한 기타 충청북도 영동군의 고의∙과실이 없는 사유로
        인한 경우
        <br />② 충청북도 영동군은 서비스 및 서비스에 게재된 정보, 자료, 사실의
        신뢰도, 정확성 등에 대해서는 보증을 하지 않으며 이로 인해 발생한 회원의
        손해에 대하여는 책임을 부담하지 아니합니다.
        <br />
      </li>
    </ol>
    <p>
      제14조(규정의 준용)
      <br />① 본 약관은 대한민국법령에 의하여 규정되고 이행됩니다.
      <br />② 본 약관에 규정되지 않은 사항에 대해서는 관련법령 및 상관습에
      의합니다.
      <br />
    </p>
    <p>
      제15조(분쟁의 조정 및 기타)
      <br />① 충청북도 영동군은 위치정보와 관련된 분쟁에 대해 당사자간 협의가
      이루어지지 아니하거나 협의를 할 수 없는 경우에는 위치정보의 보호 및 이용
      등에 관한 법률 제28조 규정에 의한 방송통신위원회에 재정을 신청할 수
      있습니다.
      <br />② 충청북도 영동군 또는 서비스 이용자는 위치정보와 관련된 분쟁에 대해
      당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는
      개인정보보호법 제40조의 규정에 의한 개인정보분쟁조정위원회에 조정을 신청할
      수 있습니다.
      <br />
    </p>
    <p>
      제16조 (충청북도 영동군의 연락처)
      <br />
      충청북도 영동군의 담당부서 및 연락처 등은 다음과 같습니다.
      <br />
    </p>
    <ol>
      <li>담당부서 : 충청북도 영동군 행정과</li>

      <li>대 표 자 : 충청북도 영동군수</li>

      <li>주 소 : 충청북도 영동군 영동읍 동정로 1</li>

      <li>
        대표전화 : 043-740-3114
        <br />
        부칙
        <br />
        제1조 (시행일) 이 약관은 2024년 4월 1일부터 시행합니다.
        <br />
        제2조 위치정보관리책임자는 2024년 4월을 기준으로 다음과 같이 지정합니다.
        <br />
      </li>

      <li>소 속 : 충청북도 영동군 행정과</li>

      <li>책임자 : 충청북도 영동군 행정과 과장</li>

      <li>연락처 :</li>

      <li>이메일 :</li>
    </ol>
  </Item>
);

const ThirdPartyProvisionItem = () => (
  <Item id="thptyProvdAgreAt" title="개인정보 제3자 제공동의(필수)">
    <h3>개인정보의 제 3자 제공 및 공유에 대한 동의</h3>
    <p>
      충청북도 영동군은 회원이 공개를 동의한 경우나 충청북도 영동군의 이용약관,
      회사가 별도로 정한 정책 및 운영 규정에 어긋나는 행위를 하여 법적인 조치가
      요구되거나 관련 정부 기관의 요구가 있는 경우를 제외하고는 어떠한 경우에도
      본 개인 정보 보호정책에서 고지한 범위를 넘어 회원의 개인정보를 이용하거나
      타인 또는 타기업, 기관에 제공하지 본 개인정보 보호정책에 의한 회원의
      개인정보 제공 및 공유에 추가하여 개인정보를 제공하거나 공유하는 경우에는
      사전에 회원에게 제공받거나 공유하는 자가 누구이며 주된 사업이 무엇인지,
      제공 또는 공유되는 개인 정보 항목이 무엇인지, 개인정보를 제공하거나
      공유하는 목적이 무엇인지 등에 대해 개인정보 보호정책, 이메일 또는 서면을
      통해 고지한 후 이에 다만, 다음의 경우에는 관련 법령의 규정에 의하여 회원의
      동의 없이 개인정보를 제공하는 것이 가능합니다. ① 통계작성, 학술연구 또는
      시장조사를 위하여 필요한 경우로서 특정 개인을 알아 볼 수 없는 형태로
      가공하여 제공하는 경우 ② 금융실명거래 및 비밀보장에 관한 법률, 신용정보의
      이용 및 보호에 관한 법률, 전기통신기본법, 전기통신사업법, 지방세법,
      소비자기본법, 한국은행법, 형사소송법 등 법률에 특별한 규정이 있는 경우
      충청북도 영동군은 서비스 제공을 위하여 제공된 개인 정보는 별도 동의를 득한
      경우를 제외하고는 서비스 해지(탈퇴 신청 포함) 후 30일까지 보관합니다.
    </p>
  </Item>
);

export default TermsOfService;
