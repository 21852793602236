import {
  Button,
  ButtonGroup,
  Card,
  Modal,
  ModalOwnProps,
  Typography,
} from '@mui/material';
import { PropsWithChildren, ReactNode, useState } from 'react';
import './useConfirmModal.scss';

type MaybePromise<T> = T | PromiseLike<T>;

interface Props extends Omit<ModalOwnProps, 'children' | 'open' | 'setOpen'> {
  cancelText?: string;
  confirmText?: string;
  text?: ReactNode;
}

export function useConfirmModal(
  onClick: (isCanceled: boolean) => MaybePromise<boolean> | MaybePromise<void>,
  defaultOpen = false,
) {
  const [open, setOpen] = useState(defaultOpen);

  const handleClick = async (isCanceled: boolean) => {
    if (await onClick(isCanceled)) {
      setOpen(false);
    }
  };

  return {
    open,
    setOpen,
    Modal: (props: PropsWithChildren<Props>) => (
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="confirm-modal"
        slotProps={{ backdrop: { className: 'modal-backdrop' } }}
      >
        <Card className="modal-box">
          <Typography className="modal-text" variant="h3">
            {props.text}
            {props.children}
          </Typography>
          <ButtonGroup fullWidth>
            <Button id="modal-cancel-btn" onClick={() => handleClick(true)}>
              {props.cancelText ?? '취소'}
            </Button>
            <Button
              id="modal-ok-btn"
              onClick={() => handleClick(false)}
              variant="contained"
            >
              {props.confirmText ?? '확인'}
            </Button>
          </ButtonGroup>
        </Card>
      </Modal>
    ),
  };
}

export default useConfirmModal;
