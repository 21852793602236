import { toggleInterest } from '@/features/formDataSlice';
import { selectInterestList } from '@/features/selectors';
import { useGetAllInterestsQuery } from '@/services/alrimiApi';
import { FormControl, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ToggleButton from '../../components/ToggleButton';

interface Props {
  disabled: boolean;
}

export function SignUpInterests({ disabled }: Props) {
  const dispatch = useDispatch();
  const { data } = useGetAllInterestsQuery();
  const userInterests = useSelector(selectInterestList);

  return (
    <form action="" method="get" className="signUp-form">
      <FormControl className="flex-row">
        <span id="interest-title">선택하신 분야의 정보를 알려드려요.</span>
        <div className="interest-select-text">관심분야를 선택하세요.</div>
        {disabled ? (
          <TextField
            className="small-field"
            disabled={true}
            value={userInterests?.join(', ')}
            variant="standard"
          />
        ) : (
          data?.map((item) => (
            <ToggleButton
              isToggled={userInterests?.includes(item.cmmnCode)}
              key={item.cmmnCode}
              onToggle={(e, isToggled) => {
                const { value } = e.currentTarget;
                dispatch(toggleInterest({ isToggled, value }));
              }}
              title={item.cmmnCodeNm}
              value={item.cmmnCode}
            />
          ))
        )}
      </FormControl>
    </form>
  );
}

export default SignUpInterests;
