import { setAuthData } from '@/features/authSlice';
import { clearFormData } from '@/features/formDataSlice';
import { selectFormData } from '@/features/selectors';
import native from '@/native';
import {
  useLazyGetUserIfExistsQuery,
  useSignUpMutation,
  useUpdateUserMutation,
} from '@/services/alrimiApi';
import { SignInResponse } from '@/types';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import useAuth from './useAuth';
import useConfig from './useConfig';
import useLoginCheck from './useLoginCheck';

export function useSignUp(pin: string) {
  const { setAuthToken } = useAuth();
  const config = useConfig();
  const dispatch = useDispatch();
  const { openWallet } = useLoginCheck();
  const navigate = useNavigate();
  const formData = useSelector(selectFormData);
  const [getUserIfExistsQuery] = useLazyGetUserIfExistsQuery();
  const [mutation] = useSignUpMutation();
  const [updateUser] = useUpdateUserMutation();

  const signUp = async () => {
    await openWallet();
    const content = JSON.stringify(formData);
    const invitationUrl = config['INVITATION_URL'];
    await native.signUp({ content, invitationUrl });

    let data: SignInResponse | undefined = undefined;
    try {
      data = await getUserIfExistsQuery(formData.ciCrtfcky as string).unwrap();
    } catch (e) {
      Sentry.captureException(e, { data: formData });
    }

    if (data?.useAt !== true) {
      data = await mutation({
        ...formData,
        idntyCrtfctIssuDt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      }).unwrap();
    } else {
      await updateUser(formData);
      await updateUser({ mobileToken: formData.mobileToken });
    }

    batch(() => {
      const action = setAuthData({
        ...data,
        authToken: data?.id,
      });
      dispatch(action);
      dispatch(clearFormData());
    });
    await setAuthToken(data!.id, pin);
    window.history.replaceState(null, '', '/');
    navigate('/?complete=1', { state: null });
  };
  return { signUp };
}

export default useSignUp;
