import logo_home from '@/img/logo_home.svg';
import { AppBar, Toolbar } from '@mui/material';
import clsx from 'clsx';
import { HTMLAttributes, PropsWithChildren } from 'react';
import { Outlet } from 'react-router';
import './Layout.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  appBar?: boolean;
  toolbar?: boolean;
}

export function Layout({
  appBar,
  children,
  ...props
}: PropsWithChildren<Props>) {
  const toolbar = (
    <Toolbar disableGutters>
      <img src={logo_home} />
    </Toolbar>
  );

  return (
    <>
      {appBar && <AppBar color="default">{toolbar}</AppBar>}
      {!appBar && props.toolbar && <header>{toolbar}</header>}
      <main {...props} className={clsx(props.className, { appBar })}>
        {children ?? <Outlet />}
      </main>
    </>
  );
}

export default Layout;
