import { SignInResponse, UserFormData } from '@/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './axiosBaseQuery';

const baseUrl = process.env.REACT_APP_BASE_URL ?? '';

export const alrimiApi = createApi({
  reducerPath: 'alrimi',
  baseQuery: axiosBaseQuery({ baseUrl: baseUrl + '/api/v1/' }),
  endpoints: (builder) => ({
    checkCitizen: builder.mutation<
      Omit<Citizen, 'rrNumber'>,
      Pick<Citizen, 'rrNumber' | 'userName'>
    >({
      query: ({ userName, rrNumber }) => ({
        url: `cert/citizen`,
        body: { userName, rrNumber },
        method: 'POST',
      }),
    }),
    getAccessPointList: builder.query<AccessPointList, void>({
      query: () => 'access-points',
    }),
    getAllInterests: builder.query<InterestList, void>({
      query: () => 'interests',
    }),
    getUserIfExists: builder.query<SignInResponse, string>({
      query: (userCi) => ({
        headers: { userCi },
        url: 'users/exist',
      }),
    }),
    getUserById: builder.query<SignInResponse, string>({
      query: (userId) => `users/${userId}`,
      providesTags: [{ type: 'User' }],
    }),
    login: builder.query<LoginResponse, string>({
      query: (userId) => `users/login/${userId}`,
    }),
    requestCertInfo: builder.mutation<ReqInfo, string>({
      query: (reqNum) => ({
        url: 'cert/reqinfo',
        body: { reqNum },
        method: 'POST',
      }),
    }),
    signUp: builder.mutation<SignInResponse, UserFormData>({
      query: (body) => ({
        url: 'users',
        body,
        method: 'POST',
      }),
    }),
    updateUser: builder.mutation<string, Partial<UserFormData>>({
      query: ({ id, interestList, mobileToken, ...formData }) => ({
        url: `users/${id}`,
        body: { intrsts: interestList, moblphonTkn: mobileToken, ...formData },
        method: 'PATCH',
      }),
      invalidatesTags: [{ type: 'User' }],
    }),
    withdraw: builder.mutation<string, string>({
      query: (userId) => ({
        url: `users/${userId}`,
        method: 'DELETE',
      }),
    }),
  }),
  tagTypes: ['User'],
});

export const {
  useCheckCitizenMutation,
  useGetAllInterestsQuery,
  useGetUserByIdQuery,
  useGetUserIfExistsQuery,
  useLazyGetAccessPointListQuery,
  useLazyGetUserByIdQuery,
  useLazyGetUserIfExistsQuery,
  useLazyLoginQuery,
  useRequestCertInfoMutation,
  useSignUpMutation,
  useUpdateUserMutation,
  useWithdrawMutation,
} = alrimiApi;

export default alrimiApi.reducer;
