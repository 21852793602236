import { addAll, toggleInterest } from '@/features/formDataSlice';
import { selectFormData } from '@/features/selectors';
import useLogin from '@/hooks/useLogin';
import {
  useGetAllInterestsQuery,
  useGetUserByIdQuery,
  useUpdateUserMutation,
} from '@/services/alrimiApi';
import { Button, FormControl, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import ToggleButton from '../../components/ToggleButton';

interface Props {
  disabled?: boolean;
}

export function InterestsUpdate({ disabled }: Props) {
  const dispatch = useDispatch();
  const { data } = useGetAllInterestsQuery();
  const { auth } = useLogin();
  const { data: user } = useGetUserByIdQuery(auth.authToken);
  const navigate = useNavigate();
  const formData = useSelector(selectFormData);
  const [updateUser] = useUpdateUserMutation();

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleReset = () => {
    dispatch(addAll({ id: user?.id, interestList: user?.interestList }));
  };

  const handleSave = async () => {
    await updateUser(formData);
    handleBackClick();
  };

  useEffect(() => {
    if (user) {
      handleReset();
    }
  }, [user]);

  return (
    <>
      <form
        action=""
        method="get"
        className="signUp-form interests-update-wrapper"
        style={{ paddingTop: '5em' }}
      >
        <FormControl className="flex-row">
          <div
            className="interest-select-text interests-update-text"
            style={{ paddingBottom: '1em' }}
          >
            관심분야를 선택하세요.
          </div>
          {disabled ? (
            <TextField
              className="small-field"
              disabled={true}
              value={formData.interestList?.join(', ')}
              variant="standard"
            />
          ) : (
            data?.map((item) => (
              <ToggleButton
                isToggled={formData.interestList?.includes(item.cmmnCode)}
                key={item.cmmnCode}
                onToggle={(e, isToggled) => {
                  const { value } = e.currentTarget;
                  dispatch(toggleInterest({ isToggled, value }));
                }}
                title={item.cmmnCodeNm}
                value={item.cmmnCode}
              />
            ))
          )}
        </FormControl>
      </form>
      <div className="interests-update-container">
        <div className="interests-update-btn-box">
          <Button
            className="interests-update-btn interests-update-btn-back"
            onClick={handleBackClick}
            variant="outlined"
          >
            뒤로
          </Button>
          <Button
            className="interests-update-btn interests-update-btn-reset"
            onClick={handleReset}
            variant="outlined"
          >
            초기화
          </Button>
        </div>
        <div className="interests-update-btn-box">
          <Button
            className="interests-update-btn interests-update-btn-save"
            onClick={handleSave}
            variant="outlined"
          >
            저장
          </Button>
        </div>
      </div>
    </>
  );
}

export default InterestsUpdate;
