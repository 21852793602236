import { PropsWithChildren, useRef, useState } from 'react';
import PinField from 'react-pin-field';
import './PinCreate.scss';

type Props = {
  length?: number;
  savePin: (pin: string) => void;
};

export function PinCreate({
  children,
  length = 6,
  savePin,
}: PropsWithChildren<Props>) {
  const ref = useRef<HTMLInputElement[]>([]);
  const [pin, setPin] = useState('');

  const handlePinChange = (code: string) => {
    setPin(code);
    if (code.length === length) {
      ref.current[0].focus();
    }
  };

  const validate = (key: string) => {
    savePin('');
    if (key.length < length) return;
    if (pin === key) {
      savePin(pin);
      ref.current.forEach((input) => input.blur());
    } else {
      ref.current.forEach((input) => (input.value = ''));
      ref.current[0].focus();
    }
  };

  return (
    <div className="pin-field-container">
      <span id="pin-text">
        간편비밀번호를 등록하시면, 6자리 숫자 입력으로 로그인 하실 수 있습니다.
      </span>
      <div className="pin-label">PIN 입력</div>
      <div className="pin-field-box">
        <PinField
          className="pin-field"
          inputMode="numeric"
          length={length}
          onChange={(code) => code && handlePinChange(code)}
          type="password"
        />
      </div>
      <div className="pin-label">입력 확인</div>
      <div className="pin-field-box">
        <PinField
          ref={ref}
          className="pin-field"
          inputMode="numeric"
          length={length}
          onChange={(code) => validate(code)}
          type="password"
        />
      </div>
      {children}
    </div>
  );
}

export default PinCreate;
