import useLogin from '@/hooks/useLogin';
import useSessionStorage from '@/hooks/useSessionStorage';
import arrow from '@/img/ic_arrow_back.svg';
import fingerprint from '@/img/ic_fingerprint.svg';
import { useEffect, useState } from 'react';
import PinField from 'react-pin-field';
import { useNavigate } from 'react-router';
import LoginBranding from '../../components/login/LoginBranding';
import './LoginPin.scss';

export function LoginPin() {
  const { login, loginWithBio } = useLogin();
  const navigate = useNavigate();
  const [redirect] = useSessionStorage<string>('redirect', '/');
  const [code, setCode] = useState<string>('');
  const keypadNumbers = Array.from({ length: 9 }, (_, index) => index);

  useEffect(() => {
    const pinFields = document.querySelectorAll('.pin-field');
    pinFields.forEach((pinField, index) => {
      pinField.classList.toggle('filled', code.length > index);
    });
  }, [code]);

  const handleClickBiometrics = async () => {
    if (await loginWithBio()) {
      navigate(redirect);
    }
  };

  const handleLogin = async (pin: string) => {
    try {
      if (await login(pin)) {
        navigate(redirect);
      } else {
        setCode('');
      }
    } catch (e: any) {
      alert(typeof e === 'string' ? e : e.message);
      setCode('');
    }
  };

  const handleNumberClick = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const number = event.currentTarget.textContent;
    if (!number) return;

    const newCode = code + number;
    if (newCode.length < 6) {
      setCode(newCode);
    } else {
      await handleLogin(newCode);
    }
  };

  const handleDelete = () => {
    setCode(code.slice(0, -1));
  };

  return (
    <div className="login-common login-pin">
      <LoginBranding />
      <div className="pin-field-wrapper">
        <PinField
          className={`pin-field ${code.length > 0 ? 'filled' : ''}`}
          inputMode="decimal"
          length={6}
          type="password"
          onChange={(code) => setCode(code)}
        />
      </div>
      <div className="keypad-wrapper">
        {keypadNumbers.map((number) => (
          <button
            key={number}
            className="keypad-button"
            onClick={handleNumberClick}
          >
            {number + 1}
          </button>
        ))}
        <img
          alt="fingerprint"
          className="ic-fingerprint"
          onClick={handleClickBiometrics}
          src={fingerprint}
        />
        <button key={0} className="keypad-button" onClick={handleNumberClick}>
          0
        </button>
        <img
          src={arrow}
          alt="arrow"
          className="ic-arrow"
          onClick={handleDelete}
        />
      </div>
    </div>
  );
}

export default LoginPin;
