import { ButtonProps, Button as MUIButton } from '@mui/material';
import clsx from 'clsx';
import './Button.scss';

export function Button(props: ButtonProps) {
  return (
    <MUIButton
      variant="contained"
      {...props}
      className={clsx(props.className, { btn: props.variant !== 'outlined' })}
    />
  );
}

export default Button;
