import { useCallback, useEffect, useRef, useState } from 'react';
import { filter, fromEvent, Observable, Subject } from 'rxjs';

type Event = Partial<StorageEvent>;

const isText = process.env.NODE_ENV === 'test';
const observable$ = new Subject<Event>();

const useSessionStorage = <T>(
  key: string,
  initialValue?: T,
  raw = false,
): [T, (value: T) => void] => {
  const observer = useRef<Observable<Event>>(observable$);
  const getValue = useCallback(() => {
    try {
      const sessionStorageValue = sessionStorage.getItem(key);
      if (typeof sessionStorageValue !== 'string') {
        sessionStorage.setItem(
          key,
          raw ? String(initialValue) : JSON.stringify(initialValue),
        );
        return initialValue;
      } else {
        return raw
          ? sessionStorageValue
          : JSON.parse(sessionStorageValue || 'null');
      }
    } catch {
      return initialValue;
    }
  }, [key, initialValue, raw]);

  const [state, setState] = useState<T>(getValue);

  const setSession = useCallback(
    (state: T) => {
      try {
        const serializedState = raw ? String(state) : JSON.stringify(state);
        sessionStorage.setItem(key, serializedState);
        if (isText) {
          (observable$ as Subject<Event>).next({
            key,
            storageArea: sessionStorage,
            newValue: serializedState,
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    [key],
  );

  useEffect(() => {
    const observer = isText
      ? observable$
      : fromEvent<StorageEvent>(window, 'storage').pipe(
          filter((e) => e.storageArea === sessionStorage && e.key === key),
        );

    const subscription = observer.subscribe(() => {
      setState(getValue());
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [key, getValue]);

  return [state, setSession];
};

export default useSessionStorage;
