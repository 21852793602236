import axios from 'axios';
import { useEffect } from 'react';
import useSessionStorage from './useSessionStorage';

export function useConfig() {
  const [config, setConfig] = useSessionStorage('config', {} as Config);

  const fetchData = async () => {
    const res = await axios.get<ConfigModel[]>('/urls');
    const data = res.data.reduce(
      (acc, item) => {
        acc[item.id] = item.value;
        return acc;
      },
      <Config>{},
    );
    setConfig(data);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return config;
}

export default useConfig;
