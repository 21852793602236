import { Link } from 'react-router-dom';
import logo_yd_arm from '../img/logo_yd_arm.svg';

export default function Welcome() {
  return (
    <div className="app_02 app_common">
      <div className="txt_sec">
        <p className="txt">
          <img src={logo_yd_arm} alt="영동알리미" />
          <span>에서는</span>
        </p>
        <ul>
          <li>
            영동군민증(영동군민), 고향사랑기부증(고향사랑기부자),
            영동사랑증(타지역)을 발급해 드려요.
          </li>
          <li>영동군의 다양한 혜택과 소식을 전해드려요.</li>
        </ul>
        <Link to="/signUp" className="app_02_btn">
          신청하기
        </Link>
      </div>

      <div className="img_sec">
        <p className="cr_img">
          <img src="img/c_img.svg" alt="영동군 캐릭터" />
        </p>
        <p className="footer_logo">
          <img src="img/city_logo.svg" alt="영동군로고" />
        </p>
      </div>
    </div>
  );
}
