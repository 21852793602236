import { toggleInterest } from '@/features/formDataSlice';
import { useGetAllInterestsQuery } from '@/services/alrimiApi';
import {
  FormControl,
  FormLabel,
  InputBaseComponentProps,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import ToggleButton from '../../components/ToggleButton';
import './UserPersonalForm.scss';

interface Props {
  disabled: boolean;
  formData: Auth;
  onUpdate: (name: string, value: string | string[]) => void;
}

const numericProps: InputBaseComponentProps = {
  inputMode: 'numeric',
  pattern: '\\d+',
};

export function UserPersonalForm(props: Props) {
  const { disabled, formData, onUpdate: updateForm } = props;
  const dispatch = useDispatch();
  const { data, isSuccess } = useGetAllInterestsQuery();
  const interestList = isSuccess
    ? data.map(({ cmmnCodeNm, cmmnCode }) => ({
        value: cmmnCode,
        name: cmmnCodeNm,
      }))
    : [];

  const userInterests = formData.interestList
    .map((id) => interestList.find((item) => item.value == id)?.name)
    .join(', ');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    updateForm(name, value.trim());
  };
  const handleGender = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { name, value } = e.currentTarget;
    updateForm(name, value);
  };

  return (
    <form action="" method="get" className="signUp-form">
      <FormControl className="number-container">
        <FormLabel className="w-100">연락처</FormLabel>
        <TextField
          disabled={disabled}
          name="mobileNumber"
          required
          type="phone"
          onChange={handleChange}
          className="small-field"
          inputProps={{ ...numericProps, maxLength: 11 }}
          value={formData.mobileNumber}
          variant="standard"
        />
      </FormControl>
      <FormControl>
        <FormLabel>생년월일</FormLabel>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format="YYYY-MM-DD"
            className="date-picker"
            onChange={(value) =>
              updateForm('brthdy', value?.format('YYYYMMDD') ?? '')
            }
            slotProps={{
              textField: { disabled, placeholder: '', variant: 'standard' },
            }}
            value={formData.brthdy ? dayjs(formData.brthdy) : null}
          />
        </LocalizationProvider>
      </FormControl>
      <FormControl className="flex-row">
        <FormLabel className="w-100">성별</FormLabel>
        {disabled ? (
          <TextField
            className="small-field"
            disabled={true}
            variant="standard"
            value={formData.gender === 'M' ? '남성' : '여성'}
          ></TextField>
        ) : (
          <>
            <ToggleButton
              isToggled={formData.gender === 'M'}
              name="gender"
              onToggle={handleGender}
              title="남성"
              value="M"
            />
            <ToggleButton
              isToggled={formData.gender === 'F'}
              name="gender"
              onToggle={handleGender}
              title="여성"
              value="feM"
            />
          </>
        )}
      </FormControl>
      <FormControl className="flex-row">
        <FormLabel className="w-100">관심분야</FormLabel>
        {disabled ? (
          <TextField
            className="small-field"
            disabled={true}
            value={userInterests}
            variant="standard"
          />
        ) : (
          interestList.map((item) => (
            <ToggleButton
              isToggled={formData.interestList.includes(item.value)}
              key={item.value}
              onToggle={(e, isToggled) => {
                const { value } = e.currentTarget;
                dispatch(toggleInterest({ isToggled, value }));
              }}
              title={item.name}
              value={item.value}
            />
          ))
        )}
      </FormControl>
    </form>
  );
}

export default UserPersonalForm;
