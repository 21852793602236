import useAuth from '@/hooks/useAuth';
import useSessionStorage from '@/hooks/useSessionStorage';
import { ReactComponent as sortDown } from '@/img/ic_A-Z_sort_dw.svg';
import { ReactComponent as sortUp } from '@/img/ic_A-Z_sort_up.svg';
import {
  useLazyGetNoticeListQuery,
  useLazyGetSearchCountQuery,
} from '@/services/noticeApi';
import { UserType } from '@/types';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

const icon: Record<Order, React.FunctionComponent> = {
  asc: sortDown,
  desc: sortUp,
};

const defaultOrderMap: Record<NoticeColumn, Order> = {
  ntcn_sndng_dt: 'desc',
  ntcn_ntt_sj: 'asc',
};

export function useNoticeList() {
  const { auth, authToken } = useAuth();
  const [getNoticeList, { data, isFetching, isLoading }] =
    useLazyGetNoticeListQuery();
  const [getSearchCount, { data: searchCount }] = useLazyGetSearchCountQuery();
  const navigate = useNavigate();
  const { postId } = useParams();
  const searchRef = useRef<HTMLInputElement>(null);
  const [, setRedirect] = useSessionStorage<string>('redirect', '/');
  const [notices, setNotices] = useState<Notice[]>([]);

  const isCivilian = auth?.userType === UserType.CIVILIAN;
  const paths = isCivilian ? ['notify/custom', 'notify'] : ['notify'];
  const defaultPageable = {
    page: 0,
    path: paths[0],
    size: 10,
    sort: '',
  };

  const [pageable, setPageable] = useState<Pageable>(defaultPageable);
  const [tab, setTab] = useState(0);
  const { sort = '' } = pageable;
  const [sortedColumnName, order] = sort.split(',') as [NoticeColumn, Order];

  const fetchNoticeList = async (pageable: Pageable) => {
    const { content } = await getNoticeList(pageable).unwrap();
    setNotices(pageable.page == 0 ? content ?? [] : [...notices, ...content]);
    setPageable(pageable);
  };

  const getSortIcon = (columnName: NoticeColumn) => {
    return icon[
      sortedColumnName === columnName ? order : defaultOrderMap[columnName]
    ];
  };

  const handleChangeTab = async (
    event: React.SyntheticEvent,
    value: number,
  ) => {
    const path = paths[value];
    setTab(value);
    await fetchNoticeList({ ...pageable, ...defaultPageable, path });
  };

  const handleSearch = async () => {
    const keyword = searchRef.current?.value;
    keyword && (await getSearchCount(keyword));
    await fetchNoticeList({ ...pageable, keyword, page: 0 });
  };

  const handleShowMore = async () => {
    if (data?.last == true) return;
    await fetchNoticeList({ ...pageable, page: pageable.page + 1 });
  };

  const handleSortDate = async () => {
    let { sort } = pageable;
    if (sort === 'ntcn_sndng_dt,desc') {
      sort = 'ntcn_sndng_dt,asc';
    } else if (sort === 'ntcn_sndng_dt,asc') {
      sort = '';
    } else {
      sort = 'ntcn_sndng_dt,desc';
    }
    await fetchNoticeList({ ...pageable, page: 0, sort });
  };

  const handleSortSubject = async () => {
    let { sort } = pageable;
    if (sort === 'ntcn_ntt_sj,asc') {
      sort = 'ntcn_ntt_sj,desc';
    } else if (sort === 'ntcn_ntt_sj,desc') {
      sort = '';
    } else {
      sort = 'ntcn_ntt_sj,asc';
    }
    await fetchNoticeList({ ...pageable, page: 0, sort });
  };

  useEffect(() => {
    if (authToken) {
      fetchNoticeList(pageable);
    } else {
      setRedirect(`/notices?postId=${postId}`);
      navigate('/login');
    }
  }, [authToken]);

  return {
    handleChangeTab,
    handleSearch,
    handleShowMore,
    handleSortDate,
    handleSortSubject,
    isLast: data?.last,
    isLoading: isFetching || isLoading,
    notices,
    pageable,
    searchCount,
    searchRef,
    sortedColumnName,
    SortIconDt: getSortIcon('ntcn_sndng_dt'),
    SortIconSj: getSortIcon('ntcn_ntt_sj'),
    tab,
  };
}
