import { useLazyGetNoticeByIdQuery } from '@/services/noticeApi';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { PropsWithChildren, useState } from 'react';

interface NoticeProps extends PropsWithChildren<Notice> {
  expanded: boolean;
  onExpand: (expanded: boolean) => void;
  tab: number;
}

export function NoticeDetail({ expanded, onExpand, ...props }: NoticeProps) {
  const [getNotice, { data }] = useLazyGetNoticeByIdQuery();
  const [readAt, setReadAt] = useState(props.readAt);
  const handleChange = (e: unknown, expanded: boolean) => {
    onExpand(expanded);
    if (expanded) {
      getNotice(
        props.tab === 0 ? props.ntcnNttId : `custom/${props.ntcnNttId}`,
      );
      setReadAt(true);
    }
  };
  return (
    <Accordion
      className={clsx('box', { active: expanded })}
      elevation={0}
      expanded={expanded}
      onChange={handleChange}
      sx={{
        backgroundColor: 'unset',
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        className="box_btn"
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className="notifi-tag-container">
          {props.children}
          <p className="label">
            {readAt ? (
              <img src="img/ar_label.svg" alt="확인" />
            ) : (
              <img src="img/ar_label_on.svg" alt="미확인" />
            )}
          </p>
        </div>
        <Typography className="notifi-text tit">{props.ntcnNttSj}</Typography>
        <Typography className="notifi-date date">
          {props.ntcnSndngDt || props.frstRegistDt}
        </Typography>
      </AccordionSummary>

      <AccordionDetails className="box_ctt">
        <pre className="txt">{data?.ntcnDetailCn}</pre>
        <div className="d_txt">
          <ul>
            <li className={data?.refrncTelno ? 'sec2' : 'sec1'}>
              <dl className="">
                <dt>담당부서</dt>
                <dd>{props.chrgMiryfcNm || '테스트'}</dd>
              </dl>
              {data?.refrncTelno && (
                <dl>
                  <dt>문의처</dt>
                  <dd>
                    <a href={`tel:${data.refrncTelno}`}>{data.refrncTelno}</a>
                  </dd>
                </dl>
              )}
            </li>
            {data?.atchmnflList.length !== 0 && (
              <li className="sec1">
                <dl>
                  <dt>첨부파일</dt>
                  <dd>
                    {data?.atchmnflList.map((file) => (
                      <button key={file.atchmnflId} type="button">
                        <span className="dw_txt">{file.atchmnflOrginlNm}</span>
                        <a
                          className="dw_ico"
                          href={`/api/v1/notify/download/${file.atchmnflId}`}
                        >
                          <img src="img/file_down_ico.svg" alt="다운로드" />
                        </a>
                      </button>
                    ))}
                  </dd>
                </dl>
              </li>
            )}
            <p className="link_area">
              {data?.ntcnOrginlUrl && (
                <a href={data.ntcnOrginlUrl} className="detail_link">
                  자세한 내용 바로가기
                </a>
              )}
            </p>
          </ul>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default NoticeDetail;
