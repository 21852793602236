import Layout from '@/components/layout/Layout';
import { withdraw } from '@/features/authSlice';
import { clearFormData } from '@/features/formDataSlice';
import useConfirmModal from '@/hooks/modal/useConfirmModal';
import useAuth from '@/hooks/useAuth';
import right from '@/img/ic_right_line.svg';
import sori from '@/img/ic_sori.svg';
import woori from '@/img/ic_woori.svg';
import UserPersonalForm from '@/pages/personal/UserPersonalForm';
import { useGetUserByIdQuery, useWithdrawMutation } from '@/services/alrimiApi';
import { Button, ButtonGroup } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import './PersonalInfoUpdate.scss';

export function PersonalInfoUpdate() {
  const { auth, authToken, clearToken } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, isSuccess } = useGetUserByIdQuery(authToken);
  const [disabled, setDisabled] = useState(true);
  const [withdrawMutation] = useWithdrawMutation();

  const handleUpdate = () => {
    return;
  };

  const handleWithdraw = () => {
    setOpen(true);
  };

  const handleClickOk = async () => {
    const res = await withdrawMutation(authToken).unwrap();
    dispatch(clearFormData());
    dispatch(withdraw());
    await clearToken();
    navigate('/login');
    return true;
  };

  const handleMove = () => {
    navigate('/update/interests');
  };

  const handleClick = () => {
    navigate('/update/pin');
  };

  const { Modal, setOpen } = useConfirmModal(
    async (isCanceled) => isCanceled || (await handleClickOk()),
  );

  return (
    <Layout id="personal-info" toolbar>
      <div className="personal-container">
        {auth.gender === 'M' ? (
          <img src={woori} className="ic-woorisori" />
        ) : (
          <img src={sori} className="ic-woorisori" />
        )}
        <div id="personal-name">{auth.name}</div>
        <div className="issue-date">
          발급일 {dayjs(auth.idntyCrtfctIssuDt).format('YYYY.MM.DD')}
        </div>
        <div className="item-container">
          <div className="item-box"></div>
        </div>
      </div>
      <UserPersonalForm
        disabled={disabled}
        formData={isSuccess ? { ...auth, ...data } : auth}
        onUpdate={handleUpdate}
      />
      <Button
        className="update-btn"
        color="inherit"
        endIcon={<img src={right} />}
        onClick={handleMove}
      >
        관심분야 수정
      </Button>
      <Button
        className="personal-pin"
        color="inherit"
        endIcon={<img src={right} />}
        onClick={handleClick}
      >
        핀 번호 변경
      </Button>
      <ButtonGroup className="btn-group">
        <Button className="withdraw" onClick={handleWithdraw}>
          회원탈퇴
        </Button>
      </ButtonGroup>
      <Modal text="정말 탈퇴하시겠습니까?" />
    </Layout>
  );
}

export default PersonalInfoUpdate;
