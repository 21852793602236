import useSecureStorage from '@/hooks/useSecureStorage';
import { useRef, useState } from 'react';
import PinField from 'react-pin-field';
import { useNavigate } from 'react-router';
import Button from './Button';
import './PinCreate.scss';

type Props = {
  length?: number;
  savePin?: (pin: string) => void;
};

export function PinUpdate({ length = 6, savePin = () => null }: Props) {
  const firstRef = useRef<HTMLInputElement[]>([]);
  const secondRef = useRef<HTMLInputElement[]>([]);
  const { setValue } = useSecureStorage('pin');
  const [pin, setPin] = useState('');
  const navigate = useNavigate();

  const handlePinChange = (code: string) => {
    setPin(code);
    if (code.length === length) {
      secondRef.current[0].focus();
    }
  };

  const validate = (key: string) => {
    savePin('');
    if (key.length < length) return;
    if (pin === key) {
      savePin(pin);
      secondRef.current.forEach((input) => input.blur());
    } else {
      secondRef.current.forEach((input) => (input.value = ''));
      secondRef.current[0].focus();
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleReset = () => {
    setPin('');
    firstRef.current.forEach((input) => (input.value = ''));
    secondRef.current.forEach((input) => (input.value = ''));
  };

  const handleSave = () => {
    if (pin.length === length) {
      setValue(pin);
      handleBackClick();
    }
  };

  return (
    <>
      <div className="pin-field-container pin-update-wrapper">
        <span id="pin-text pin-update-text">핀 번호 변경</span>
        <div className="pin-field-wrapper">
          <div className="label-container">
            <div className="pin-label">PIN 입력</div>
          </div>
          <div className="pin-field-box">
            <PinField
              ref={firstRef}
              className="pin-field"
              inputMode="numeric"
              length={length}
              onChange={(code) => code && handlePinChange(code)}
              type="password"
            />
          </div>
          <div className="label-container">
            <div className="pin-label">입력 확인</div>
          </div>
          <div className="pin-field-box">
            <PinField
              ref={secondRef}
              className="pin-field"
              inputMode="numeric"
              length={length}
              onChange={(code) => validate(code)}
              type="password"
            />
          </div>
          <div className="pin-update-container">
            <div className="pin-update-btn-box">
              <Button
                className="pin-update-btn pin-update-btn-back"
                onClick={handleBackClick}
                variant="outlined"
              >
                뒤로
              </Button>
              <Button
                className="pin-update-btn pin-update-btn-reset"
                onClick={handleReset}
                variant="outlined"
              >
                초기화
              </Button>
            </div>
            <div className="pin-update-btn-box">
              <Button
                className="pin-update-btn pin-update-btn-save"
                onClick={handleSave}
                variant="outlined"
              >
                저장
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PinUpdate;
