import native from '@/native';

export function useHive<T>(name: string) {
  const box = name;
  return {
    get boxExists() {
      return native.boxExists({ box });
    },
    deleteFromDisk: () => native.deleteFromDisk({ box }),
    flush: () => native.flush({ box }),
    get: (key: string, defaultValue?: T) =>
      native.get({ box, defaultValue, key }) as Promise<T>,
    async isExists() {
      return (await this.boxExists) && (await this.keys)?.includes('authToken');
    },
    get keys() {
      return native.keys({ box }) as Promise<string[]>;
    },
    openBox: (key?: string) => native.openBox({ box, key }),
    put: (key: string, value: T) => native.put({ box, key, value }),
  };
}

export default useHive;
