import useAuth from '@/hooks/useAuth';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import './Certification.scss';

type Props = {
  name?: string;
  brthdy?: string;
  gender?: string;
  issueDate?: string;
};

/** 영동군민증 */
export function Certification(props: Props) {
  const { auth } = useAuth();
  const isMale = auth.gender === 'M';
  return (
    <>
      <p className="info_label">
        {auth.userType === '1' && <img src="img/label_1.svg" alt="군민혜택" />}
      </p>
      <div className="info_txt">
        <p className="info_title">
          <img src="img/name_ico.svg" alt="아이콘" />
          {auth.userType === '1' ? '영동군민증' : '영동사랑증'}
        </p>
        <p className={clsx('name', { woori: isMale })}>{auth.name}</p>
        <dl className="date">
          <dt>발급일</dt>
          <dd>{dayjs(auth.idntyCrtfctIssuDt).format('YYYY.MM.DD')}</dd>
        </dl>
        <Link to="/personal" className="my_link">
          내 정보 보기
        </Link>
      </div>
      <p className="info_img">
        <img
          src={isMale ? 'img/box_woori_bg.svg' : 'img/box_sori_bg.svg'}
          alt="군민증 캐릭터"
        />
      </p>
    </>
  );
}

export default Certification;
