import { addAll } from '@/features/formDataSlice';
import { selectFormData } from '@/features/selectors';
import error from '@/img/ic_error.svg';
import warn from '@/img/ic_warn.svg';
import { useCheckCitizenMutation } from '@/services/alrimiApi';
import { UserType } from '@/types';
import * as Sentry from '@sentry/react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Button from '../../components/Button';
import './ResidencyVerification.scss';

const lastDigitsRegex = /^[0-9]{7}$/;

const { REACT_APP_ENV } = process.env;

export function ResidencyVerification() {
  const [checkCitizen, result] = useCheckCitizenMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lastDigitsRef = useRef<HTMLInputElement>(null);
  const formData = useSelector(selectFormData);
  const [birth, setBirth] = useState('');

  useEffect(() => {
    if (!formData.ciCrtfcDt) {
      navigate('/signUp/1'); // 본인인증 페이지로 돌아가기
    }
    setBirth(formData.brthdy?.substring(2) ?? '');
  }, [formData.brthdy]);

  const handleResult = (data: Omit<Citizen, 'rrNumber'>) => {
    const { admdngNm, admdngCode, riNm, regnCode } = data;
    const userType = admdngCode?.startsWith('43740')
      ? UserType.CIVILIAN
      : UserType.GENERAL;
    dispatch(
      addAll({
        adstrdCode: admdngCode || '0',
        adstrdNm: admdngNm ?? '',
        legaldongCode: regnCode ?? '',
        legaldongNm: riNm ?? '',
        userType,
      }),
    );
  };

  const handleCheckButton = async () => {
    if (formData.name === undefined) {
      alert('본인 인증이 필요합니다.');
      navigate(-1);
      return;
    }
    const lastDigits = lastDigitsRef.current?.value ?? '';
    lastDigitsRef.current?.blur();
    if (!lastDigitsRegex.test(lastDigits)) {
      alert('주민번호 뒷자리 7자리를 숫자로만 입력하세요.');
      return;
    }
    const rrNumber = birth + lastDigits;
    try {
      const result = checkCitizen({
        rrNumber,
        userName: formData.name ?? '홍길동',
      });
      handleResult(await result.unwrap());
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      alert('거주민 확인 요청이 실패했습니다.');
    }
  };

  return (
    <div className="residence-confirmation-wrapper">
      <div className="residence-confirmation-info">
        <div className="info-data">
          <div className="warning">
            주민등록 번호는 영동군 거주확인 용도로 입력만 받으며 수집하지
            않습니다.
          </div>
          조회용도로만 사용 된 후 결과값(거주확인 유/무, 행정동명, 행정동
          코드)만 저장합니다.
        </div>
        <div className="residence-confirmation-info-content">
          <div className="residence-confirmation-info-content-reconfirm">
            {'\u00A0'}영동군 거주자로 확인된 경우 유효기간은 6개월입니다.
            유효기간 만료 후 영동군민 전용 서비스 이용 시 재확인 절차가 진행될
            수 있습니다.
          </div>
          <div className="residence-confirmation-restriction info-text">
            {'\u00A0'}영동군 미거주자는 일부 서비스 이용에 제약이 있을 수
            있습니다.
          </div>
        </div>
      </div>
      <div className="resident-registration-number-input-title">
        주민등록번호 입력
      </div>
      <div className="residence-confirmation-content-input">
        <input
          disabled={REACT_APP_ENV !== 'development'}
          type="text"
          placeholder="앞 6자리 입력"
          maxLength={6}
          onChange={(e) => {
            const inputVal = e.target.value;
            const sixDigitVal = inputVal.slice(0, 6); // 입력 값을 6자리로 자름
            setBirth(sixDigitVal); // 6자리 값으로 상태 업데이트
          }}
          pattern="[0-9]*"
          value={birth}
        />
        <div className="input-dash">-</div>
        <input
          type="password"
          placeholder="뒤 7자리 입력"
          maxLength={7}
          pattern="[0-9]{7}"
          ref={lastDigitsRef}
          onChange={(e) => {
            if (e.target.value.length === 7) {
              handleCheckButton();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleCheckButton();
            }
          }}
        />
      </div>
      {result.isSuccess ? (
        <div className="residential-confirmation-result">
          <div className="residential-confirmation-result-error">
            {formData.userType === '1' ? (
              <img src={warn} alt="warning" />
            ) : (
              <img src={error} alt="error" className="ic-error" />
            )}
            <div className="residential-confirmation-result-error-text">
              거주 확인 결과
            </div>
          </div>
          {formData.userType === '1' ? (
            <div className="confirmation-result-residence">영동군 거주</div>
          ) : (
            <div className="warning">영동군 미거주</div>
          )}

          <div className="residence-confirmation-restriction">
            영동군 미거주자는 일부 서비스 이용에 제약이 있을 수 있습니다.
          </div>
        </div>
      ) : (
        <Button onClick={handleCheckButton}>확인요청</Button>
      )}
    </div>
  );
}

export default ResidencyVerification;
