import Button from '@/components/Button';
import LoginBranding from '@/components/login/LoginBranding';
import useAsync from '@/hooks/useAsync';
import useLogin from '@/hooks/useLogin';
import { useLoginCheck } from '@/hooks/useLoginCheck';
import useSessionStorage from '@/hooks/useSessionStorage';
import fingerPrint from '@/img/ic_fingerprint.svg';
import pin from '@/img/ic_pin.svg';
import yd from '@/img/logo.svg';
import Welcome from '@/pages/Welcome';
import { useNavigate } from 'react-router';
import './Login.scss';

export function Login() {
  const { loginWithBio } = useLogin();
  const { hasToken } = useLoginCheck();
  const { value } = useAsync(hasToken, []);
  const navigate = useNavigate();
  const [redirect] = useSessionStorage<string>('redirect', '/');

  const needToSignUp = value === false;

  if (needToSignUp) {
    return <Welcome />;
  }

  const handleClickBiometrics = async () => {
    if (await loginWithBio()) {
      navigate(redirect);
    }
  };

  const handleClickPin = () => {
    navigate('/login/pin');
  };

  return (
    <div className="login-common">
      <LoginBranding flexGrow={true} />

      {!needToSignUp && (
        <div className="login-method-wrapper">
          <span className="login-method-text">지문</span>
          <span className="login-method-text">핀 번호</span>
          <img
            src={fingerPrint}
            alt="biometrics"
            onClick={handleClickBiometrics}
          />
          <img src={pin} alt="pin" onClick={handleClickPin} />
        </div>
      )}
      {value !== true && (
        <Button
          className="btn-sign-up"
          onClick={() => navigate('/signUp')}
          variant={needToSignUp ? 'contained' : 'text'}
        >
          회원가입
        </Button>
      )}
      <div className="login-bottom">
        <img src={yd} className="yd-icon" />
      </div>
    </div>
  );
}

export default Login;
