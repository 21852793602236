import App from '@/App';
import ReduxProvider from '@/components/ReduxProvider';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import './window';

Sentry.init({
  dsn: 'https://0479d565c2e73b74cd25d258bf3e014b@o4505248881180672.ingest.sentry.io/4506649010765824',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'alrimi.yd21.go.kr',
        /^https:\/\/(.*\.)?es6\.kr\//,
      ],
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <ReduxProvider>
    <App />
  </ReduxProvider>,
);

reportWebVitals();
