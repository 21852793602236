import Certification from '@/components/Certification';
import useAuth from '@/hooks/useAuth';
import HometownDonationCertification from '@/pages/home/donation/HometownDonationCertification';
import { Toolbar } from '@mui/material';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import PreferentialFacilities from './PreferentialFacilities';
import './allCertification.scss';

export default function AllCertification() {
  const { auth } = useAuth();
  return (
    <div className="app_box_area">
      <Toolbar className="logo_img">
        <img src="img/top_logo.svg" alt="영동알리미" />
      </Toolbar>
      <Swiper pagination={true} modules={[Pagination]}>
        {auth.userHmtwnLove && (
          <SwiperSlide>
            <PreferentialFacilities className="box_type1">
              <HometownDonationCertification
                name={auth.name}
                brthdy={auth.brthdy}
                donation={auth.userHmtwnLove}
              />
            </PreferentialFacilities>
          </SwiperSlide>
        )}
        <SwiperSlide>
          {auth.userType === '1' ? (
            <PreferentialFacilities>
              <Certification />
            </PreferentialFacilities>
          ) : (
            <div className="info_box">
              <Certification />
            </div>
          )}
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
