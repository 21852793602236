import useAuth from '@/hooks/useAuth';
import { useGetNoticeCountQuery } from '@/services/noticeApi';
import { Link } from 'react-router-dom';
import AllCertification from './AllCertification';

export default function Home() {
  const { auth } = useAuth();
  const { data } = useGetNoticeCountQuery();
  return (
    <>
      <AllCertification />
      {/* 하단영역 */}
      <div className="box_bottom">
        {/* 앱실행 바 */}
        <div className="txt_bar">
          <p className="txt">
            앱 실행중입니다. 앱 실행중입니다. 앱 실행중입니다. 앱 실행중입니다.
            앱 실행중입니다. 앱 실행중입니다. 앱 실행중입니다. 앱 실행중입니다.
            앱 실행중입니다. 앱 실행중입니다. 앱 실행중입니다. 앱 실행중입니다.
            앱 실행중입니다. 앱 실행중입니다. 앱 실행중입니다.
          </p>
        </div>
        {/* //앱실행 바 */}
        {/* 광고배너 */}
        <div className="main_bn">
          <a href="#">
            <img src="img/ba_sample.svg" alt="배너샘플" />
          </a>
        </div>
        {/* //광고배너 */}
        {/* 알림영역 */}
        <div className="bell_area">
          <div className="bell_title">
            <span>{auth.name}</span>님의 알림
          </div>
          <div className="bell_box">
            <Link to="/notices" className="total_bell">
              <p className="tit">전체알림</p>
              <p className="num">
                <span>{data?.totalNotifyCount ?? '?'}</span>건
              </p>
              <img src="img/ar_ico.svg" className="ar_ico" alt="화살표아이콘" />
            </Link>
            <Link to="/notices" className="un_bell">
              <p className="tit">미확인알림</p>
              <p className="num">
                <span>{data?.unreadNotifyCount ?? '?'}</span>건
              </p>
              <img src="img/ar_ico.svg" className="ar_ico" alt="화살표아이콘" />
            </Link>
          </div>
        </div>
        {/* //알림영역 */}
      </div>
      {/* //하단영역 */}
    </>
  );
}
