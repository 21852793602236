import { selectFormData } from '@/features/selectors';
import phoneIcon from '@/img/ic_cellphone.svg';
import { useRequestCertInfoMutation } from '@/services/alrimiApi';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import packages from '../../../package.json';
import './IdentityVerification.scss';

interface Props {
  goNext: () => void;
}

export function IdentityVerification({ goNext }: Props) {
  const form = useRef<HTMLFormElement>(null);
  const reqInfo = useRef<HTMLInputElement>(null);
  const [requestCertInfo] = useRequestCertInfoMutation();
  const formData = useSelector(selectFormData);

  const handleClick = async () => {
    const data = await requestCertInfo(generateRequestNumber()).unwrap();
    reqInfo.current!.value = data.reqInfo;
    form.current?.submit();
  };

  useEffect(() => {
    if (formData.ciCrtfcDt) {
      goNext();
    }
  }, [formData.ciCrtfcDt]);

  return (
    <form
      className="verify-container"
      action="https://pcc.siren24.com/pcc_V3/jsp/pcc_V3_j10_v2.jsp"
      method="post"
      ref={form}
    >
      <input type="hidden" name="reqInfo" value="" ref={reqInfo} />
      <input
        type="hidden"
        name="retUrl"
        value={`32${packages.proxy}/api/v1/cert/pcc_v3_popup_seed_v2`}
      />
      <input type="hidden" name="verSion" value="2" />
      <span id="verify-text">
        가입자의 소중한 개인정보 보호를 위해 본인인증 후 가입해주세요.
      </span>
      <Button className="verify-phone-btn" onClick={handleClick}>
        <img src={phoneIcon} />
        휴대폰 인증
      </Button>
    </form>
  );
}

function generateRequestNumber(): string {
  const randomString = Math.random().toString(36).substring(2);
  const currentTime = dayjs().format('YYYYMMDDHHmmssSSSZZ');
  return `${currentTime}-${randomString}`;
}

export default IdentityVerification;
