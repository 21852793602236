import { ButtonProps } from '@mui/material/Button';
import React, { MouseEvent } from 'react';
import Button from './Button';

type Props = ButtonProps & {
  isToggled: boolean;
  onToggle: (event: MouseEvent<HTMLButtonElement>, isToggled: boolean) => void;
};

const ToggleButton: React.FC<Props> = ({
  isToggled,
  name,
  onToggle,
  title,
  value,
}) => {
  return (
    <Button
      className={`toggle-btn ${isToggled ? 'active' : ''}`}
      variant="outlined"
      size="medium"
      name={name}
      value={value}
      onClick={(e) => onToggle(e, !isToggled)}
    >
      {title}
    </Button>
  );
};

export default ToggleButton;
