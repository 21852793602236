import auth from '@/features/authSlice';
import formData from '@/features/formDataSlice';
import alrimi, { alrimiApi } from '@/services/alrimiApi';
import { Middleware, combineReducers, configureStore } from '@reduxjs/toolkit';
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import logger from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rtkQueryErrorLogger } from './services/rtkQueryErrorLogger';

const middleware = (getDefaultMiddleware: CurriedGetDefaultMiddleware) => {
  const defaultMiddleware = getDefaultMiddleware({
    serializableCheck: false,
  });

  const additionalMiddleware = [
    // eslint-disable-next-line prettier/prettier
    alrimiApi.middleware,
    rtkQueryErrorLogger,
  ];

  process.env.NODE_ENV === 'development' &&
    additionalMiddleware.push(logger as Middleware);

  return [...defaultMiddleware, ...additionalMiddleware];
};

const persistConfig = {
  key: 'root',
  storage: storage,
};

const reducer = combineReducers({
  auth,
  formData,
  alrimi,
});

export const store = configureStore({
  middleware,
  reducer: persistReducer(persistConfig, reducer),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof reducer>;

export const persistor = persistStore(store);

export default { store, persistor };
