export interface SignInResponse
  extends ResponseData,
    UsageStatus,
    UserWithType {
  id: string;
  name: string;
  brthdy: string;
  gender: Gender;
  mobileNumber: string;
  idntyCrtfctIssuDt: string;
  interestList: string[];
}

export const enum UserType {
  /** 영동 군민 */
  CIVILIAN = '1',
  /** 일반 사용자 */
  GENERAL = '2',
}

export type UserWithType<T = unknown> = T & {
  userType?: UserType;
};

export type UserFormData = Consent & UserWithType<UserData>;
