import { SerializedError } from '@reduxjs/toolkit';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import * as Sentry from '@sentry/react';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';

const { sessionStorage } = window;

export function axiosBaseQuery(
  { baseUrl }: { baseUrl: string } = { baseUrl: '' },
): BaseQueryFn<string | AxiosArgs, unknown, AxiosQueryError> {
  axios.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      Sentry.captureException(error, { data: error });
      throw error;
    },
  );
  return async (args) => {
    if (typeof args === 'string') {
      args = { url: args };
    }
    const { url, body: data, headers = {}, ...config } = args ?? {};

    const authToken = sessionStorage.getItem('authToken');
    if (authToken) {
      headers.Authorization = `Bearer ${authToken}`;
    }

    try {
      const response = await axios({
        ...config,
        url: baseUrl + url,
        data,
        headers,
      });
      return { data: response.data };
    } catch (error) {
      const axiosError = error as AxiosError;
      const { message, response } = axiosError;
      return {
        error: {
          cause: axiosError,
          message,
          response,
        } as AxiosQueryError,
      };
    }
  };
}

interface AxiosArgs {
  url: string;
  method?: AxiosRequestConfig['method'];
  body?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
  headers?: AxiosRequestConfig['headers'];
}

export interface AxiosQueryError extends SerializedError {
  cause: AxiosError;
  response: AxiosResponse;
}

export default axiosBaseQuery;
