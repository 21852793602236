import CompleteModal from '@/components/CompleteModal';
import useLogin from '@/hooks/useLogin';
import Welcome from '@/pages/Welcome';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import clsx from 'clsx';
import { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import './Main.scss';

interface MainProps {
  path?: NavigationValue;
}

type NavigationValue = 'personal' | 'home' | 'notices';

interface BottomNavigationItem {
  className: string;
  label: string;
  value: NavigationValue;
}

const navigationItems: BottomNavigationItem[] = [
  {
    className: 'user',
    label: '내정보관리',
    value: 'personal',
  },
  {
    className: 'home',
    label: '홈',
    value: 'home',
  },
  {
    className: 'bell',
    label: '알림보기',
    value: 'notices',
  },
];

function Main({ path = 'home' }: MainProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { auth } = useLogin();

  const navi = location.pathname.replace('/', '') || path;
  const searchParams = new URLSearchParams(window.location.search);
  const complete = searchParams.get('complete') !== null;

  if (!auth.authToken) {
    return <Welcome />;
  }

  return (
    <div className="app_common">
      <CompleteModal
        open={complete && isModalOpen}
        setOpen={setIsModalOpen}
        text="회원가입이 완료되었습니다."
      />
      <Outlet />
      <BottomNavigation
        className="app_bar"
        component="nav"
        showLabels
        value={navi}
        onChange={(_, value) => navigate('/' + value)}
      >
        {navigationItems.map(({ className, label, value }) => (
          <BottomNavigationAction
            classes={{ label: clsx(className, { on: value == navi }) }}
            color="black"
            key={value}
            label={label}
            value={value}
          />
        ))}
      </BottomNavigation>
    </div>
  );
}

export default Main;
