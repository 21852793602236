import { RootState } from '@/store';
import assert from 'assert';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import useHive from './useHive';
import useSecureStorage from './useSecureStorage';
import useSessionStorage from './useSessionStorage';

export default function useAuth() {
  const auth = useSelector((state: RootState) => state.auth);
  const authBox = useHive<string>('auth');
  const { checkValue, deleteAll, setValue } = useSecureStorage('pin');
  const [authToken, setSession] = useSessionStorage<string>(
    'authToken',
    '',
    true,
  );

  const clearToken = async () => {
    setSession('');
    await deleteAll();
    if (await authBox.boxExists) {
      await authBox.deleteFromDisk();
    }
  };

  const getAuthToken = useCallback(
    async (pin: string) => {
      assert(await hasToken(), 'e10. 회원가입이 필요합니다.');
      assert(await checkValue(pin), 'e11. 올바르지 않은 PIN 입니다.');
      assert(await authBox.openBox(pin), 'e12. 로그인을 실패했습니다.');

      const token = await authBox.get('authToken');
      assert(token, 'e14. 로그인을 실패했습니다.');
      setSession(token);
      return token;
    },
    [authBox],
  );

  const hasToken = useCallback(() => {
    return authBox.isExists();
  }, [authBox]);

  const setAuthToken = async (authToken: string, pin: string) => {
    assert(authToken, 'e20. 회원가입을 실패했습니다.');
    setSession(authToken);
    await setValue(pin);
    if (await authBox.openBox(pin)) {
      await authBox.put('authToken', authToken);
      await authBox.flush();
      return;
    }
    throw 'e21. 회원가입을 실패했습니다.';
  };
  return { auth, authToken, clearToken, getAuthToken, hasToken, setAuthToken };
}
