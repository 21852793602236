import ydArm from '@/img/logo_yd_arm.svg';
import clsx from 'clsx';
import './LoginBranding.scss';

export function LoginBranding({ flexGrow = false }: { flexGrow?: boolean }) {
  return (
    <div className={clsx('login-branding', { 'flex-grow': flexGrow })}>
      <img src={ydArm} alt="yd_alrimi" className="yd-alrimi" />
      <div className="login-text-container">
        <span className="login-text">함께하는</span>
        <span className="login-text-blue">군민</span>
        <span className="login-text">살맛 나는</span>
        <span className="login-text-green">영동</span>
      </div>
    </div>
  );
}

export default LoginBranding;
