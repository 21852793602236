import PinCreate from '@/components/PinCreate';
import useAuth from '@/hooks/useAuth';
import useLogin from '@/hooks/useLogin';
import native from '@/native';
import { Button } from '@mui/material';
import { QRCodeCanvas } from 'qrcode.react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';

export function LoginQr() {
  const { setAuthToken } = useAuth();
  const { login } = useLogin();
  const navigate = useNavigate();
  const { token = '' } = useParams();
  const [pin, setPin] = useState('');

  const handleClick = async () => {
    if (pin.length !== 6) return;
    await setAuthToken(token, pin);
    login(pin);
    navigate('/');
  };

  return native.isMobile() ? (
    <PinCreate savePin={setPin}>
      <Button onClick={handleClick} variant="contained">
        Save PIN
      </Button>
    </PinCreate>
  ) : (
    <QRCodeCanvas value={location.href} />
  );
}

export default LoginQr;
