import { UserWithType } from '@/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: UserWithType<Auth> = {
  name: '',
  brthdy: '',
  gender: undefined,
  userType: undefined,
  interestList: [],
  authToken: '',
  idntyCrtfctIssuDt: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthData: (
      state,
      action: PayloadAction<Partial<{ [K in keyof Auth]: Auth[K] }>>,
    ) => {
      return { ...state, ...action.payload };
    },
    withdraw: () => {
      return initialState;
    },
  },
});

export const { setAuthData, withdraw } = authSlice.actions;
export default authSlice.reducer;
