import native from '@/native';

export default function useSecureStorage(key: string) {
  return {
    async authenticate() {
      return await native.authenticate();
    },
    async checkValue(value: string) {
      return (await native.check({ key, value })) as boolean;
    },
    async deleteAll() {
      await native.deleteAll();
    },
    async readValue() {
      return await native.read({ key });
    },
    async setValue(value: string) {
      await native.write({ key, value });
    },
  };
}
