import { UserFormData } from '@/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: UserFormData = {
  useStplatAgreAt: false,
  indvdlinfoUseAgreAt: false,
  thptyProvdAgreAt: false,
  cntrAt: false,
  interestList: [],
};

const formDataSlice = createSlice({
  name: 'formData',
  initialState,
  reducers: {
    addAll: (state, action: PayloadAction<Partial<UserFormData>>) => {
      return { ...state, ...action.payload };
    },
    clearFormData: () => initialState,
    consentAll: (state) => {
      state.useStplatAgreAt = true;
      state.indvdlinfoUseAgreAt = true;
      state.thptyProvdAgreAt = true;
    },
    setFormData: <T extends keyof UserFormData>(
      state: UserFormData,
      action: PayloadAction<{
        name: T;
        value: UserFormData[T];
      }>,
    ) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    toggleConsent: (
      state: UserFormData,
      { payload }: PayloadAction<keyof Consent>,
    ) => {
      state[payload] = !state[payload];
    },
    toggleInterest: (state, { payload }) => {
      const { isToggled, value } = payload;
      const interestList = state.interestList ?? [];
      state.interestList = isToggled
        ? Array.from(new Set(interestList.concat(value)))
        : interestList.filter((item) => item !== value);
    },
  },
});

/* prettier-ignore */
export const {
  addAll,
  clearFormData,
  consentAll,
  setFormData,
  toggleConsent,
  toggleInterest,
} = formDataSlice.actions;

export default formDataSlice.reducer;
