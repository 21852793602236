import useAuth from '@/hooks/useAuth';
import messageIcon from '@/img/ic_message.svg';
import { useGetAllInterestsQuery } from '@/services/alrimiApi';
import { Button, Tab, Tabs } from '@mui/material';
import clsx from 'clsx';
import { useState } from 'react';
import NoticeDetail from './NoticeDetail';
import { useNoticeList } from './useNoticeList';

import './NoticeList.scss';

export function NoticeList() {
  const { auth } = useAuth();
  const {
    handleChangeTab,
    handleSearch,
    handleShowMore,
    handleSortDate,
    handleSortSubject,
    isLast,
    isLoading,
    notices,
    pageable,
    searchCount,
    searchRef,
    sortedColumnName,
    SortIconDt,
    SortIconSj,
    tab,
  } = useNoticeList();
  const [expandedId, setExpandedId] = useState(0);
  const tabCount = [
    searchCount?.searchIndvdlNtcnNttCount,
    searchCount?.searchNotifyCount,
  ];
  const total = tabCount.reduce((acc: number, cur) => acc + (cur ?? 0), 0);
  const message = isLoading ? '로딩중...' : '검색 결과가 없습니다.';

  const a11yProps = (index: number, name: string) => ({
    'aria-controls': `tabpanel-${index}`,
    id: `tab-${index}`,
    label: pageable.keyword ? (
      <>
        <div className="counter"></div>
        {name}
        <div className={clsx('counter', { zero: tabCount[index] == 0 })}>
          ({tabCount[index]})
        </div>
      </>
    ) : (
      name
    ),
  });

  return (
    <div className="notifi-container ar_list">
      <div className="notifi-top">
        <div className="ar_sch">
          <p>
            <input
              ref={searchRef}
              type="text"
              className="ar_sch_ip"
              placeholder="검색어를 입력하세요."
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  searchRef.current?.blur();
                  handleSearch();
                }
              }}
            />
            <input
              type="button"
              className="ar_sch_button"
              value="확인"
              onClick={handleSearch}
            />
          </p>
        </div>
        {pageable.keyword && (
          <div className="count-message">
            총 <span className="counter">{total}</span> 건의 알림이 있습니다.
          </div>
        )}
        {auth.userType == '1' && (
          <Tabs
            classes={{ flexContainer: 'ar_tab' }}
            value={tab}
            onChange={handleChangeTab}
            variant="fullWidth"
            TabIndicatorProps={{ style: { display: 'none' } }}
          >
            <Tab
              className={clsx({ active: tab === 0 })}
              {...a11yProps(0, '군민 알림')}
            />
            <Tab
              className={clsx({ active: tab === 1 })}
              {...a11yProps(1, '분야별 알림')}
            />
          </Tabs>
        )}
      </div>
      <div className="notifi-accordion-wrapper list_sec">
        <div className="notifi-buttons">
          <Button
            className={clsx('sort-btn', {
              active: sortedColumnName === 'ntcn_ntt_sj',
            })}
            onClick={handleSortSubject}
          >
            제목정렬 <SortIconSj />
          </Button>
          <Button
            className={clsx('sort-btn', {
              active: sortedColumnName === 'ntcn_sndng_dt',
            })}
            onClick={handleSortDate}
          >
            알림 일자 정렬 <SortIconDt />
          </Button>
        </div>
        {notices.length === 0 && (
          <div className="notifi-message">
            <img src={messageIcon} />
            {message}
          </div>
        )}
        <div className="notifi-accordion">
          {notices?.map((item) => (
            <NoticeDetail
              expanded={item.ntcnNttId === expandedId}
              key={item.ntcnNttId}
              onExpand={(expanded) => {
                if (expanded) {
                  setExpandedId(item.ntcnNttId);
                  // TODO: window.scrollTos
                } else if (expandedId === item.ntcnNttId) {
                  setExpandedId(0);
                }
              }}
              tab={tab}
              {...item}
            >
              <Tags interestCodeList={item.interestCode?.split(',') ?? []} />
            </NoticeDetail>
          ))}
          {isLoading || isLast || (
            <Button className="notifi-more-btn" onClick={handleShowMore}>
              더보기
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

const colorMap: Record<string, string> = {
  '0': 'cate_type1',
  '1': 'cate_type2',
  '2': 'cate_type3',
  '3': 'cate_type4',
  '4': 'cate_type5',
  '5': 'cate_type6',
  '6': 'cate_type7',
  '7': 'cate_type8',
};

function Tags({ interestCodeList }: { interestCodeList: string[] }) {
  const { data: allInterests } = useGetAllInterestsQuery();
  let code = '0';
  if (interestCodeList.length < 7) {
    code = interestCodeList[0];
  }
  const name =
    allInterests?.find((it) => it.cmmnCode == code)?.cmmnCodeNm ?? '전체';
  return (
    <span className={`cate ${colorMap[code]}`} key={code}>
      {name}
    </span>
  );
}

export default NoticeList;
