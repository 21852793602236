let isMobile = Object.prototype.hasOwnProperty.call(
  window,
  'flutter_inappwebview',
);

window.addEventListener('flutterInAppWebViewPlatformReady', () => {
  isMobile = true;
});

export function nativeCall<
  Argument extends NativeArgs | undefined,
  Result = undefined,
>(
  method: string,
  handler = 'Native',
  fallback = () => {
    console.debug('모바일 앱에서만 실행 가능합니다.', method);
    return Promise.resolve(undefined as Result);
  },
): (kwargs?: Argument) => Promise<Result> {
  if (isMobile) {
    return (kwargs?: Argument) =>
      window.flutter_inappwebview?.callHandler<Result>(handler, method, kwargs);
  }
  return fallback;
}

export default {
  getCredentials: nativeCall<undefined, Credential[]>('getCredentials'),
  getToken: nativeCall<undefined, string>('getToken', 'Firebase'),
  initializeApp: nativeCall('initializeApp', 'Firebase'),
  openWallet: nativeCall<WalletConfig>('openWallet'),
  requestNotification: nativeCall<undefined, boolean>('requestNotification'),
  requestPermissions: nativeCall<undefined, void>('requestPermissions'),
  signUp: nativeCall<SignUpData>('signUp'),

  /* Geofence */
  addGeofence: nativeCall<Geofence>('addGeofence', 'Geofence'),
  clearGeofenceList: nativeCall('clearGeofenceList', 'Geofence'),
  isMobile: () => isMobile,
  isRunningGeofenceService: nativeCall<undefined, boolean>(
    'isRunningService',
    'Geofence',
  ),
  isWeb: () => !isMobile,
  removeGeofenceById: nativeCall<string>('removeGeofenceById', 'Geofence'),
  startListen: nativeCall<any>('startListen', 'Geofence'),

  /* Hive */
  boxExists: nativeCall<Hive, boolean>('boxExists', 'Hive'),
  deleteBoxFromDisk: nativeCall<Hive, void>('deleteBoxFromDisk', 'Hive'),
  deleteFromDisk: nativeCall<Hive, void>('deleteFromDisk', 'Hive'),
  get: nativeCall<Hive, unknown>('get', 'Hive'),
  flush: nativeCall<Hive, void>('flush', 'Hive'),
  keys: nativeCall<Hive, string[]>('keys', 'Hive'),
  openBox: nativeCall<Hive, boolean>('openBox', 'Hive'),
  put: nativeCall<Hive, void>('put', 'Hive'),

  /* Secure Storage */
  authenticate: nativeCall<SecureStorage, boolean>(
    'authenticate',
    'SecureStorage',
  ),
  canCheckBiometrics: nativeCall<undefined, boolean>(
    'canCheckBiometrics',
    'SecureStorage',
  ),
  check: nativeCall<SecureStorage, boolean>('check', 'SecureStorage'),
  delete: nativeCall<SecureStorage>('delete', 'SecureStorage'),
  deleteAll: nativeCall('deleteAll', 'SecureStorage'),
  getAvailableBiometrics: nativeCall<undefined, boolean>(
    'getAvailableBiometrics',
    'SecureStorage',
  ),
  get isAuthenticate() {
    return nativeCall<undefined, boolean>('isAuthenticate', 'SecureStorage')();
  },
  read: nativeCall<SecureStorage, string>('read', 'SecureStorage'),
  write: nativeCall<SecureStorage, void>('write', 'SecureStorage'),
};
