import dayjs from 'dayjs';

type Props = {
  name?: string;
  brthdy?: string;
  donation: UserHmtwnLoveDTO;
};

/* 고향사랑기부증 */
export default function HometownDonationCertification(props: Props) {
  const { donation } = props;
  return (
    <>
      <p className="info_label">
        <img src="img/label_2.svg" alt="이용혜택" />
      </p>
      <div className="info_txt">
        <div className="box_num">
          <p className="txt">제 {donation.cntrbtrSn}호</p>
        </div>
        <div className="txt_area">
          <div className="title">고향사랑기부증</div>
          <div className="info_data_sec">
            <div className="info_data">
              <dl>
                <dt>성명</dt>
                <dd>{props.name}</dd>
              </dl>
              <dl>
                <dt>유효기간</dt>
                <dd>기부일로부터 1년</dd>
              </dl>
            </div>
          </div>
        </div>
        <p className="type1_date">
          {dayjs(donation.issuDe).format('YYYY.MM.DD.')}
        </p>
      </div>
      <p className="info_img">
        <img src="img/box_bg2.svg" alt="고향사랑기부증 배경" />
      </p>
    </>
  );
}
