import PinUpdate from '@/components/PinUpdate';
import Layout from '@/components/layout/Layout';
import Main from '@/pages/Main';
import SignUp from '@/pages/SignUp';
import Welcome from '@/pages/Welcome';
import Home from '@/pages/home/Home';
import Login from '@/pages/login/Login';
import LoginPin from '@/pages/login/LoginPin';
import LoginQr from '@/pages/login/LoginQr';
import NoticeList from '@/pages/notices/NoticeList';
import InterestsUpdate from '@/pages/personal/InterestsUpdate';
import PersonalInfoUpdate from '@/pages/personal/PersonalInfoUpdate';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import * as Sentry from '@sentry/react';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import './App.scss';

/** 탭바 최소 높이 없애기 */
const styleOverrides: Partial<OverridesStyleRules> = {
  root: {
    minHeight: 'unset',
  },
};

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides,
    },
    MuiTabs: {
      styleOverrides,
    },
  },
  palette: {
    primary: {
      main: '#0054a6',
      light: '#006DDB',
    },
    text: {},
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Sentry.ErrorBoundary fallback={<p>오류가 발생하였습니다.</p>}>
        <Router />
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  );
}

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/notices" element={<NoticeList />} />
          <Route path="/personal" element={<PersonalInfoUpdate />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route
          path="/login/:token"
          element={
            <Layout toolbar>
              <LoginQr />
            </Layout>
          }
        />
        <Route path="/login/pin" element={<LoginPin />} />
        <Route path="/update" element={<Layout toolbar />}>
          <Route path="interests" element={<InterestsUpdate />} />
          <Route path="pin" element={<PinUpdate />} />
        </Route>
        <Route path="/signUp" element={<SignUp />}>
          <Route path=":step" element={<SignUp />} />
        </Route>
        <Route path="/welcome" element={<Welcome />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
