import native from '@/native';
import { useLazyGetAccessPointListQuery } from '@/services/alrimiApi';

interface Geofence {
  id: string;
  data: AccessPoint;
  latitude: number;
  longitude: number;
  radius: GeofenceRadius[];
}

interface GeofenceRadius {
  id: string;
  length: number;
}

export function useGeofence(...geofenceList: Geofence[]) {
  const [getAccessPointList] = useLazyGetAccessPointListQuery();

  const updateGeofence = async () => {
    if (await native.isRunningGeofenceService()) {
      return true;
    }
    await native.startListen({
      interval: 5000,
      accuracy: 100,
      loiteringDelayMs: 60000,
      statusChangeDelayMs: 10000,
      printDevLog: false,
      geofenceList: await getGeofenceList(),
    });

    await native
      .addGeofence({
        id: 'willkomo',
        data: { apLcNm: 'WILLKOMO WiFi' },
        latitude: 36.3608888,
        longitude: 127.3464726,
        radius: [{ id: '50m', length: 50 }],
      })
      ?.catch((e) => console.error(e));
    return true;
  };

  const getGeofenceList = async () => {
    const data = await getAccessPointList().unwrap();
    const geofenceList = data.map<Geofence>((ap) => {
      const radius = [{ id: '50m', length: 50 }];
      const [longitude, latitude] = ap.apCrdnt
        .replace(/[()]/g, '')
        .split(',')
        .map((coord) => parseFloat(coord.trim()));
      return { id: ap.apNm, data: ap, latitude, longitude, radius };
    });
    return geofenceList;
  };
  return { updateGeofence };
}

export default useGeofence;
