import useConfig from '@/hooks/useConfig';
import native from '@/native';
import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import useAuth from './useAuth';
import useSessionStorage from './useSessionStorage';

export function useLoginCheck() {
  const { authToken, hasToken } = useAuth();
  const config = useConfig();
  const navigate = useNavigate();
  const [redirect] = useSessionStorage<string>('redirect', '/');
  const [credentials, setCredentials] = useSessionStorage<Credential[]>(
    'credentials',
    [],
  );

  const openWallet = () => load(authToken, config);

  useEffect(() => {
    native.requestNotification();
    if (authToken) {
      navigate(redirect);
      return;
    }
    hasToken().then((value) => {
      if (value) {
        navigate('/login');
      }
    });
  }, []);
  return { credentials, hasToken, openWallet, redirect };
}

async function load(seed: string, config: Config) {
  const { data: genesisTransactions } = await axios.get<string>('/genesis');
  await native.openWallet({
    genesisTransactions,
    label: '영동알리미',
    mediatorUrl: config['MEDIATOR_URL'],
    poolName: 'IndyYD',
    seed,
    walletId: 'walletId',
  });
  const credentials = await native.getCredentials();
  return credentials;
}

interface Credential {
  name: string;
}

export default useLoginCheck;
