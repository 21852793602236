import { setAuthData } from '@/features/authSlice';
import native from '@/native';
import { useLazyLoginQuery } from '@/services/alrimiApi';
import { AxiosQueryError } from '@/services/axiosBaseQuery';
import assert from 'assert';
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useAuth from './useAuth';

export function useLogin() {
  const { auth, authToken, clearToken, getAuthToken } = useAuth();
  const dispatch = useDispatch();
  const [loginQuery] = useLazyLoginQuery();

  const login = useCallback(
    async (pin: string) => {
      const token = await getAuthToken(pin);
      assert(token, 'e01. 회원가입이 필요합니다.');
      try {
        requestLogin(token);
      } catch (e) {
        const { status = 500 } = (e as AxiosError).response ?? {};
        if (status === 404) {
          await clearToken();
          throw 'e02. 탈퇴하여 재가입이 필요합니다.';
        } else if (status > 400) {
          throw `HTTP Status: ${status}`;
        }
        throw e;
      }
      return token;
    },
    [authToken, loginQuery],
  );

  const loginWithBio = async () => {
    if (await native.authenticate({ key: '로그인을 위해 인증해주세요' })) {
      const pin = await native.read({ key: 'pin' });
      await login(pin as string);
      return true;
    }
    return false;
  };

  const requestLogin = async (authToken: string) => {
    const result = await loginQuery(authToken);
    if (result.isError) {
      const { cause, response } = result.error as AxiosQueryError;
      throw cause;
    }
    dispatch(
      setAuthData({
        ...result.data,
        authToken,
      }),
    );
  };

  return { auth, login, loginWithBio };
}

export default useLogin;
