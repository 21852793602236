import Button from '@/components/Button';
import Certification from '@/components/Certification';
import PinCreate from '@/components/PinCreate';
import Layout from '@/components/layout/Layout';
import { clearFormData } from '@/features/formDataSlice';
import { selectFormData } from '@/features/selectors';
import useConfirmModal from '@/hooks/modal/useConfirmModal';
import useGeofence from '@/hooks/useGeofence';
import useSignUp from '@/hooks/useSignUp';
import IdentityVerification from '@/pages/signup/IdentityVerification';
import ResidencyVerification from '@/pages/signup/ResidencyVerification';
import SignUpInterests from '@/pages/signup/SignUpInterests';
import SignUpSteps from '@/pages/signup/SingUpSteps';
import TermsOfService from '@/pages/signup/TermsOfService';
import { Alert } from '@mui/material';
import { ReactNode, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import './SignUp.scss';

const steps = [
  { step: '약관동의', title: '약관 동의' },
  { step: '본인인증', title: '본인 인증' },
  { step: '영동군거주확인', title: '영동군 거주확인' },
  { step: '관심분야', title: '관심분야' },
  { step: 'PIN등록', title: '핀 번호 등록' },
];

const lastStep = steps.length - 1;

export function SignUp() {
  const { updateGeofence } = useGeofence();
  const formData = useSelector(selectFormData);
  const [alert, setAlert] = useState<ReactNode>();
  const [pin, setPin] = useState<string>('');
  const { signUp } = useSignUp(pin);
  const { Modal, setOpen } = useConfirmModal(async (isCanceled) => {
    if (isCanceled || (await updateGeofence())) {
      try {
        signUp();
      } catch (e) {
        console.debug('signUp error:', JSON.stringify(e));
        setAlert('회원가입을 실패했습니다.');
      }
      return true;
    }
    return false;
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { step: stepStr } = useParams<{ step: string }>();
  const step = stepStr ? parseInt(stepStr) : formData.ciCrtfcky ? 2 : 0;

  const validatorMap: { [step: number]: Validator } = {
    0: {
      error: (
        <>
          이용약관 및 개인정보 처리방침에 동의하셔야 가입이 가능합니다.{' '}
          <strong>약관을 확인해주세요.</strong>
        </>
      ),
      validate: () => {
        const { useStplatAgreAt, indvdlinfoUseAgreAt, thptyProvdAgreAt } =
          formData;
        const consentToAll =
          useStplatAgreAt && indvdlinfoUseAgreAt && thptyProvdAgreAt;
        return consentToAll;
      },
    },
    1: {
      error: '본인확인이 필요합니다.',
      validate: () => {
        return !!formData.ciCrtfcky;
      },
    },
    2: {
      error: '영동군 거주확인이 필요합니다.',
      validate: () => {
        return !!formData.adstrdCode;
      },
    },
    3: {
      error: '관심사를 선택해주세요.',
      validate: () => {
        return formData.interestList.length > 0;
      },
    },
    4: {
      error: 'PIN을 채워주세요.',
      validate: () => {
        return pin.length >= 6;
      },
    },
  };

  const goBack = () => {
    if (step === 0) {
      if (confirm('회원가입을 취소하시겠습니까?')) {
        dispatch(clearFormData());
        navigate('/');
      }
    } else {
      setAlert(null);
      navigate(`/signUp/${step - 1}`);
    }
  };
  const goNext = () => {
    const validator = validatorMap[step];
    if (!validator?.validate()) {
      setAlert(validator?.error);
      return;
    }

    if (step === 4) {
      setOpen(true);
      return;
    }
    setAlert(null);
    navigate(`/signUp/${step + 1}`);
  };
  return (
    <Layout toolbar id="sign-up">
      <SignUpSteps
        activeStep={step}
        progress={step + 1}
        steps={steps.map((item) => item.title)}
      >
        <TermsOfService name="모두 동의하기" />
        <IdentityVerification goNext={goNext} />
        <ResidencyVerification />
        <SignUpInterests disabled={false} />
        <PinCreate savePin={setPin} />
        <Certification
          name={formData.name}
          brthdy={formData.brthdy}
          gender={formData.gender}
          issueDate={formData.idntyCrtfctIssuDt}
        />
      </SignUpSteps>
      {alert && (
        <Alert severity="error" title="경고" className="alert-box">
          {alert}
        </Alert>
      )}
      <div className="btn-wrap">
        <Button variant="outlined" className="btn-back" onClick={goBack}>
          이전
        </Button>
        <Button className="btn-next" onClick={goNext}>
          {step === lastStep ? '완료' : '다음'}
        </Button>
      </div>
      <Modal confirmText="설정">
        영동알리미 앱은 사용자의 현재 위치를 기반으로 와이파이존 진입 안내 및
        군민 알림을 제공하기 위해 앱이 닫혀있을 때나 사용되지 않을 때도 위치
        데이터를 수집합니다. <br />
        <br />
        영동알리미 앱을 사용하고 있지 않을 때도 이 기기의 위치에 접근하도록
        허용하시겠습니까?
      </Modal>
    </Layout>
  );
}

interface Validator {
  error: ReactNode;
  validate: () => boolean;
}

export default SignUp;
