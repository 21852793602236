import { Button, Card, Modal, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import './CompleteModal.scss';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  text: string;
};

const CompleteSignUp: React.FC<Props> = ({ open, setOpen, text }: Props) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="complete-modal-container"
      slotProps={{ backdrop: { className: 'complete-modal-backdrop' } }}
    >
      <Card className="complete-modal-box">
        <Typography className="complete-modal-text" variant="h3">
          {text}
        </Typography>
        <Button id="complete-modal-close-btn" onClick={() => setOpen(false)}>
          확인
        </Button>
      </Card>
    </Modal>
  );
};

export default CompleteSignUp;
