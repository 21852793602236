import React, { useState, useEffect, useCallback } from 'react';

export interface AsyncState<T> {
  loading: boolean;
  error?: Error | unknown;
  value?: T;
}

const useAsync = <T>(fn: () => Promise<T>, deps: React.DependencyList) => {
  const [state, set] = useState<AsyncState<T>>({
    loading: true,
  });
  const memoized = useCallback(fn, deps);

  useEffect(() => {
    let mounted = true;
    const promise = memoized();

    promise.then(
      (value) => {
        if (mounted) {
          set({
            loading: false,
            value,
          });
        }
      },
      (error) => {
        if (mounted) {
          set({
            loading: false,
            error,
          });
        }
      },
    );

    return () => {
      mounted = false;
    };
  }, [memoized]);

  return state;
};

export default useAsync;
