import { alrimiApi } from './alrimiApi';

const noticeApi = alrimiApi.injectEndpoints({
  endpoints: (builder) => ({
    getNoticeById: builder.query<NoticeDetail, string | number>({
      query: (ntcnNttId) => `notify/${ntcnNttId}`,
    }),
    getNoticeCount: builder.query<NoticeCount, void>({
      query: () => 'notify/count',
      keepUnusedDataFor: 1,
    }),
    getNoticeList: builder.query<NoticeList, Pageable>({
      query: ({ path = 'notify', page = 0, keyword, size = 10, sort = '' }) => {
        const params = new URLSearchParams();
        params.set('page', page.toString());
        if (keyword) params.set('keyword', keyword);
        params.set('size', size.toString());
        if (sort) params.set('sort', sort);
        else {
          params.set('sort', 'readng_at');
          params.append('sort', 'ntcn_sndng_dt,desc');
        }
        return `${path}?${params.toString()}`;
      },
    }),
    getSearchCount: builder.query<SearchCount, string>({
      query: (keyword) => `notify/count/search?keyword=${keyword}`,
    }),
  }),
});

export const {
  useGetNoticeByIdQuery,
  useGetNoticeCountQuery,
  useLazyGetNoticeByIdQuery,
  useLazyGetNoticeListQuery,
  useLazyGetSearchCountQuery,
} = noticeApi;

export default noticeApi.reducer;
