import Box from '@mui/material/Box';
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';
import './SignUpSteps.scss';
import { Children, ReactNode } from 'react';

type Props = {
  activeStep: number;
  children: ReactNode;
  progress: number;
  steps: string[];
};

export function SignUpSteps({ activeStep, children, steps, progress }: Props) {
  return (
    <>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgressWithLabel step={progress} value={progress * 20} />
        </Box>
      </Box>
      <span className="step-title">{steps[activeStep]}</span>
      {Children.toArray(children)[activeStep]}
    </>
  );
}

function LinearProgressWithLabel(
  props: LinearProgressProps & { step: number; value: number },
) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ flex: 1, mr: 2 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }} className="step-box">
        <span className="step-current">{props.step}</span>
        <span className="step-slash">/</span>
        <span className="step-total">5</span>
      </Box>
    </Box>
  );
}

export default SignUpSteps;
