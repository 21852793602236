import { addAll } from '@/features/formDataSlice';
import native from '@/native';
import { store } from '@/store';
import * as Sentry from '@sentry/react';
import { UserType } from './types';

window.saveFormData = async ({
  name,
  birYMD,
  sex,
  fgnGbn,
  ci1,
  result,
  cellNo,
  certDate,
  fcmToken,
}) => {
  try {
    if (!result) {
      throw new Error('본인인증을 실패했습니다.');
    }
    if (!fcmToken) {
      await native.initializeApp();
      fcmToken = await native.getToken();
    }
    if (!fcmToken) {
      throw new Error('FCM 토큰이 발급되지 않았습니다.');
    }

    return store.dispatch(
      addAll({
        name,
        brthdy: birYMD,
        gender: sex,
        userType: fgnGbn as UserType, // TODO: 외국인 처리
        ciCrtfcky: ci1,
        mobileNumber: cellNo,
        mobileToken: fcmToken,
        ciCrtfcDt: certDate,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      alert(error.message);
    }
    Sentry.captureException(error, {
      data: { certDate, fcmToken, fgnGbn, result },
    });
  }
};
